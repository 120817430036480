/*
 * Flex Frame - Styles for full-width page components
 * Custom SkyKick Classes
----------------------*/

@import './components/variables';

/*---------------------
SET DEFAULTS
----------------------*/

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: $textBody;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}
body {
  display: flex;
  flex-direction: column;
}

/*---------------------
CUSTOM SCROLLBAR
----------------------*/
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: inherit;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/*---------------------
PORTAL HEADER
----------------------*/

.sk-portal-header {
  background: #fff;
  box-shadow: 0px 1px 0px rgba(196, 202, 207, 0.6);
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}
.sk-partner-brand,
.sk-account-nav {
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
.sk-partner-brand {
  display: flex;
  justify-content: flex-start;
}
.sk-partner-brand img.partner-logo {
  max-width: 180px;
  max-height: 44px;
}
.sk-account-nav {
  display: flex;
  justify-content: flex-end;
}

/*---------------------
PAGE CONTAINER
----------------------*/

.sk-flex-page {
  flex: 1;
  display: flex;
  overflow: auto;
  z-index: 0;
}

/*---------------------
CENTER PANE
----------------------*/

.sk-content-pane {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0;
}

/*---------------------
HEADER
----------------------*/

.sk-page-header {
  border-bottom: 1px solid $borderGray400;
  padding: 7px 2rem 0;
  display: flex;
  flex-direction: column;
  min-height: 49px;
}
.sk-page-header.header-light {
  background: $bgGray50;
}
.sk-navbar-primary {
  height: 34px;
  margin: 0 0 2px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sk-navbar-primary.secondary-none,
.sk-navbar-primary.single-navbar {
  // Deprecate .secondary-none
  margin: 0 0 7px 0;
}
.sk-navbar-primary .sk-navbar-heading {
  max-width: 340px;
}
.sk-navbar-primary h3 {
  padding: 4px 0 0 0;
}
.sk-navbar-secondary {
  height: 38px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sk-navbar-tertiary {
  border-bottom: 1px solid $borderGray500;
  height: 38px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
}
.sk-navbar-tertiary .sk-breadcrumb {
  margin: 0;
  line-height: 20px;
}
.sk-navbar-tertiary .sk-breadcrumb .breadcrumb-item.active {
  color: $textNavActive;
  font-weight: 500;
}
.sk-navbar-tertiary.indented {
  border-bottom: 1px solid $borderGray500;
  padding: 0;
  margin: 0 2rem;
}
.sk-navbar-panel {
  height: 34px;
  display: flex;
  align-items: center;
}

/*---------------------
LEFT PANE
----------------------*/

nav.sk-left-pane {
  order: 0;
  background: $bgGray50;
  border-right: 1px solid $borderGray500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 14rem;
}
.sk-nav-footer {
  background: $bgGray50;
  height: 48px;
  border-top: 1px solid $borderGray500;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.sk-left-pane.collapsed {
  width: 42px;
  transition: all 0.1s ease-in-out;
}

/* Collapse */

.sk-left-pane.collapsed .sk-nav-platform li.nav-item span,
.sk-left-pane.collapsed ul.sk-nav-platform ul {
  display: none;
}
.sk-left-pane.collapsed .expand-collapse-icon:after {
  content: '\00e315';
  font-family: 'Material Icons';
}
.sk-left-pane .expand-collapse-icon:after {
  content: '\00e314';
  font-family: 'Material Icons';
}

/*---------------------
PAGE CONTENT
----------------------*/

.sk-page-content {
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: row;
}
.sk-content-column {
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.sk-content-primary {
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
}
.sk-scroll-container {
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
}
.sk-content-pane .sk-page-footer {
  padding: 0;
  display: flex;
  border-top: 1px solid $borderGray500;
}
.sk-page-footer .sk-footer-content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sk-page-footer.footer-light {
  background: $bgGray50;
}
.sk-page-footer .sk-footer-content.sk-footer-wizard {
  flex: 1;
  justify-content: space-between;
}

/*---------------------
NAV - PLATFORM LEFT NAV
----------------------*/

ul.sk-nav-platform {
  margin-top: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  /* Toggle overflow:visible for popover*/
  overflow: auto;
}
.overflow-visible {
  overflow: visible !important;
}
.sk-nav-platform li.nav-item span {
  padding-left: 10px;
  vertical-align: middle;
}
.sk-nav-platform li.nav-item a {
  color: #707375;
  font-size: 13px;
  font-weight: 500;
  line-height: 36px;
  padding: 0 0 0 11px;
  display: flex;
  flex: 1;
}
.sk-nav-platform li.nav-item {
  display: flex;
  align-items: center;
}
.sk-nav-platform li.nav-item .sk-primary-parent {
  height: 36px;
}
.sk-nav-platform .panel.active {
  background: $bgGray200;
}

/* Iconography */

.sk-nav-platform .nav-item a.active .material-icons,
.sk-nav-platform .nav-item a.active:hover .material-icons {
  color: $bgBlue600;
}
.sk-nav-platform .nav-item a .material-icons {
  color: #a4aebc;
  font-size: 22px;
  display: flex;
  align-items: center;
}

/* Nav Platform */

.sk-nav-platform .nav-link.active,
.sk-nav-platform .nav-link.active:focus,
.sk-nav-platform .nav-link.active:hover {
  color: #0c0d0d;
  background: transparent;
  cursor: pointer;
}

/* Product Nav Parents */

a.sk-primary-parent.active {
  box-shadow: inset 4px 0 $bgBlue500;
}

/* Product Nav Children */

ul.sk-nav-platform ul {
  margin-top: 0;
  list-style: none;
  flex-direction: column;
}
.sk-collapse-nav {
  background: transparent;
  box-shadow: inset 4px 0 $bgBlue500;
}
.sk-collapse-nav .nav-item a.nav-link {
  font-weight: 500;
  line-height: 30px;
  padding-left: 43px;
}
.sk-collapse-nav .nav-link.active {
  cursor: pointer;
}
.sk-collapse-nav .nav-item + .nav-item {
  margin-top: 0rem;
}
.sk-collapse-nav .nav-item:last-child {
  padding-bottom: 16px;
}

/* Hover */

.sk-nav-platform li.nav-item a:hover {
  color: #333638;
}
.sk-nav-platform li.nav-item a:hover .material-icons {
  color: #5b6671;
}
.sk-collapse-nav li.nav-item a:hover {
  color: #1b1c1d;
}

/* Popover Menu - Collapsed Only */

.sk-nav-popover ul {
  width: 10rem;
}
.sk-nav-popover ul {
  padding: 0;
  margin-bottom: 0;
}
.sk-nav-popover .sk-nav-platform li.nav-item a:not(.sk-primary-parent) {
  line-height: 32px;
  padding-left: 0;
}
.sk-nav-popover .sk-nav-platform a.sk-primary-parent {
  font-size: 13px;
  font-weight: 500;
  color: #0c0d0d;
  padding-left: 0;
  margin-top: -0.5rem;
}

/*---------------------
NEW COMPONENTS - FLEX BASED
----------------------*/

/* Tabs Large */

.sk-tabs-lg .nav-link {
  border: none;
  color: $textNavLg;
  font-size: 15px;
  padding: 0 1.5rem 0 0;
}
.sk-tabs-lg .nav-link.active {
  font-weight: 500;
  color: #222;
}
.sk-tabs-lg .nav-link:hover {
  color: $textNavHover;
}

/* Tabs Base, Tabs Panel, Nav Vert */

.sk-tabs-base,
.sk-tabs-panel {
  display: flex;
  flex-wrap: wrap;
}
.sk-tabs-base .nav-link,
.sk-tabs-panel .nav-link,
.sk-nav-vert .nav-link,
.sk-nav-basic .nav-link {
  font-weight: 400;
  color: $textNavSm;
  display: block;
  padding: 0 2rem 0 0;
}
.sk-tabs-base .nav-link:hover,
.sk-tabs-panel .nav-link:hover,
.sk-nav-vert .nav-link:hover,
.sk-nav-basic .nav-link:hover {
  color: $textLink;
}
.sk-tabs-base .nav-link.active,
.sk-tabs-base .nav-link.active:hover {
  color: $textNavHover;
  font-weight: 600;
}

.sk-tabs-base .nav-link.disabled,
.sk-nav-vert .nav-link.disabled {
  color: $textDisabled;
}

/* Nav Footer */

.sk-footer-steps .nav-item .material-icons {
  color: #c8d2da;
}
.sk-footer-steps .nav-item + .nav-item {
  margin-left: 0.5rem;
}
.sk-footer-steps .nav-item {
  display: flex;
  align-items: center;
}
.sk-footer-steps .nav-link {
  color: $textNavLg;
  font-size: 14px;
  font-weight: 400;
  padding: 0 2px;
  align-items: center;
}
.sk-footer-steps .nav-link.active {
  color: $textNavActive;
  font-weight: 500;
}
.sk-footer-steps .nav-link.disabled {
  pointer-events: none;
}
.sk-footer-steps .nav-link.disabled,
.sk-footer-steps .nav-link.disabled:focus,
.sk-footer-steps .nav-link.disabled:hover {
  color: $textNavDisabled;
}
.sk-footer-steps .nav-link:hover {
  color: $textNavHover;
}

/* Tables and Content */

.sk-flex-table,
.sk-content-flex {
  overflow: hidden;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.sk-flex-table .sk-table-cap {
  padding: 7px 0;
  margin: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sk-flex-table .sk-table-cap.border-bottom {
  border-bottom: 1px solid $borderGray500;
}
.sk-flex-table .sk-scroll-container,
.sk-content-flex .sk-scroll-container {
  padding: 0 2rem;
}
.sk-heading-row {
  padding: 1rem 0 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
table.sk-sticky-header thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 2;
  box-shadow: 0px 1px $borderGray500;
}
.table.sk-sticky-header tr:first-child td {
  border-top: none;
}