@import './variables';

/*---------------------
POPOVER / TOOLTIPS
----------------------*/

.popover {
  border: 1px solid $borderGray600;
  box-shadow: 0px 1px 3px rgba(91, 102, 111, 0.3);
  font-size: 12px;
  font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
  word-wrap: break-word;
  padding: 0;
}

/* width auto (<276), or add sizing classes */
.sk-popover-sm {
  width: 200px;
}
.sk-popover-md {
  width: 230px;
}
.sk-popover-lg {
  width: 276px;
}

.popover-header {
  background-color: $bgGray50;
  border-bottom: 1px solid #e7ecf1;
  border-radius: 0.2rem 0.2rem 0 0;
  padding: 8px 1rem;
}
h3.popover-header {
  font-size: 14px;
}


/* Tooltip */
.tooltip {
  font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  opacity: 1 !important;
  word-wrap: break-word;
}
.tooltip-inner {
  padding: 10px 1rem;
  text-align: left;
}

/* Tooltip sizing */
.tooltip-lg .tooltip-inner {
  max-width: 300px;
}
.tooltip-xl .tooltip-inner{
  max-width: 400px;
}

.sk-popover-shim::after {
  content: ' ';
  top: -12px;
  left: -12px;
  bottom: 0;
  right: 0;
  margin-right: -12px;
  margin-bottom: -12px;
  position: absolute;
  z-index: -10;
}

