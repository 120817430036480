@import './variables';

/*---------------------
ALERTS
----------------------*/

.alert {
  margin-bottom: 0;
  padding: 11px 13px;
}
.alert a {
  color: #333;
  text-decoration: underline;
}
.sk-alert-text {
  vertical-align: middle;
  padding-top: 2px;
}
.alert-success {
  color: $textDark;
  background-color: $bgGreen100;
  border-color: $bgGreen100;
}
.alert-success .material-icons {
  color: $bgGreen500;
}
.alert-warning {
  color: $textDark;
  background-color: $bgYellow100;
  border-color: $bgYellow100;
}
.alert-warning .material-icons {
  color: $bgYellow500;
}
.alert-danger {
  color: $textDark;
  background-color: $bgRed100;
  border-color: $bgRed100;
}
.alert-danger .material-icons {
  color: $bgRed500;
}
.alert-info {
  color: $textDark;
  background-color: $bgTeal100;
  border-color: $bgTeal100;
}
.alert-info .material-icons {
  color: $bgTeal400;
}
.alert .md-22 {
  vertical-align: middle;
}
.alert-combo {
  line-height: 34px;
  padding: 10px 15px;
}
.alert-combo .md-22 {
  line-height: inherit;
}
.alert-note {
  color: $textDark;
  background-color: $bgGray200;
  border-color: $bgGray200;
}
.alert-note i.material-icons {
  color: $bgGray600;
}

.alert .btn-close .material-icons {
  color: $bgGray600;
}
.alert .btn-close,
.alert-dismissible .btn-close {
  padding: 1rem;
}
.alert.alert-dismissible {
  padding-right: 4rem;
}
/* BANNER BLOCK */

.alert.sk-banner-block {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 13px 16px;
  border-bottom-color: rgba(173, 173, 173, 0.3);
}
.alert.sk-banner-block.sk-light,
.alert.sk-banner-block.sk-light a {
  color: #ffffff;
}
.sk-banner-block .sk-fb-content {
  float: none;
}
.sk-banner-block .btn-close {
  padding-top: 6px;
}
.sk-banner-block.alert-primary {
  background-color: #2c3449;
}
.sk-banner-block.alert-warning {
  background-color: $bgYellow200;
}
.sk-banner-block.alert-info {
  background-color: $bgTeal200;
}
.sk-banner-block.alert-danger {
  background-color: $bgRed200;
  color: $textBody;
}

/* BANNER IN PORTAL */

body.sk-has-banner {
  margin-top: 60px;
}
.sk-has-banner .header2 {
  top: 60px;
}

/* FEEDBACK BLOCK COLUMNS */

.sk-fb-content {
  margin-right: -130px;
  width: 100%;
  float: left;
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.sk-fb-message {
  margin-left: 32px;
}
.sk-fb-combo {
  padding: 13px;
}
.sk-fb-combo .sk-fb-message {
  margin-left: 32px;
  margin-right: 130px;
}
.sk-fb-message .sk-alert-text {
  line-height: 20px;
}
.sk-fb-btn {
  width: 130px;
  padding-top: -4px;
  float: right;
}
.sk-fb-message dl {
  margin: 0;
  padding: 0;
}
