@import './variables';

/*---------------------
NAVS
----------------------*/
.nav a:hover{
  text-decoration: none;
}

.sk-tabs,
.sk-steps {
  border-bottom: 1px solid $borderGray500;
}
.sk-tabs .nav-link.active,
.sk-tabs .nav-link.active:focus,
.sk-tabs .nav-link.active:hover,
.sk-tabs .nav-item.open .nav-link,
.sk-tabs .nav-item.open .nav-link:focus,
.sk-tabs .nav-item.open .nav-link:hover {
  box-shadow: inset 0 -2px $bgBlue500;
  -moz-box-shadow: inset 0 -2px $bgBlue500;
  -webkit-box-shadow: inset 0 -2px $bgBlue500;
  background-color: transparent;
  color: $textNavHover;
  font-weight: 500;
  text-decoration: none;
}
.sk-tabs .nav-link:hover {
  box-shadow: inset 0 -2px $bgGray600;
  -moz-box-shadow: inset 0 -2px $bgGray600;
  -webkit-box-shadow: inset 0 -2px $bgGray600;
  color: $textNavHover;
}
/* TABS WITH ICONS */
.sk-tabs .nav-link .material-icons {
  color: $bgBlue500;
}
.sk-tabs .nav-link:hover .material-icons {
  color: $bgGray700;
}
.sk-tabs .nav-link.active .material-icons {
  color: $bgBlue600;
}

/* TABS */
.nav-tabs .nav-item + .nav-item {
  margin-left: 2rem;
}
.sk-tabs .nav-link {
  border: none;
  border-bottom: 1px solid transparent;
  color: $textNavSm;
  font-weight: 400;
  line-height: 48px;
  padding: 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
  border-bottom: 1px solid transparent;
}
.tabs-height-sm .nav-link {
  line-height: 36px !important;
}
.tabs-height-md .nav-link {
  line-height: 42px !important;
}
.tabs-height-lg .nav-link {
  line-height: 48px !important;
}

/*---------------------
PANEL NAVIGATION
----------------------*/

/* Nav Vert */

.sk-panel-nav .sk-nav-vert .nav-link {
  padding: 0 2rem;
}
.sk-nav-vert .nav-link {
  line-height: 39px;
  margin-bottom: 1px;
}
.sk-nav-vert .nav-link.active {
  color: #222;
  font-weight: 500;
  background-color: $bgBlue100;
}
.sk-nav-vert .nav-link:hover:not(.active) {
  background-color: $bgBlue50;
}

/* Vertical Tab Styling */
.sk-tabs-vert .nav-link.active,
.sk-tabs-vert .nav-link.active:focus,
.sk-tabs-vert .nav-link.active:hover {
  box-shadow: inset 3px 0 $bgBlue500;
  -moz-box-shadow: inset 3px 0 $bgBlue500;
  -webkit-box-shadow: inset 3px 0 $bgBlue500;
  background-color: $bgBlue100;
}
.sk-tabs-vert .nav-link:hover:not(.active) {
  background-color: $bgBlue50;
}

/* Nav Vert Wizard */

.sk-nav-vert.sk-nav-wizard .nav-link {
  color: $textDark;
  display: flex;
  padding-right: 0;
  font-size: 14px;
}
.sk-nav-vert.sk-nav-wizard .step-stack {
  display: flex;
  flex-direction: column;
  padding: 6px 0;
}
.sk-nav-vert.sk-nav-wizard .step-title {
  line-height: 20px;
}
.sk-nav-vert.sk-nav-wizard .step-title.active,
.sk-nav-vert.sk-nav-wizard .nav-child .nav-link.active {
  font-weight: 500;
  color: $textNavActive;
}
.sk-nav-vert.sk-nav-wizard .step-stack .step-meta {
  font-size: 12px;
  color: $textMeta;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sk-nav-vert.sk-nav-wizard .nav-child .nav-link {
  display: flex;
  font-size: 13px;
  line-height: 34px;
}
.sk-nav-vert.sk-nav-wizard .nav-child .nav-title {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sk-nav-vert.sk-nav-wizard .sk-nav-divider {
  height: 0;
  overflow: hidden;
  border-top: 1px solid $borderGray500;
  margin: 0 8px 0 3.5rem;
}
.sk-nav-vert.sk-nav-wizard .nav-link:hover:not(.active) {
  background-color: $bgBlue50;
  color: inherit;
}
.sk-wiz-container,
.sk-wiz-content {
  display: flex;
  overflow: auto;
  flex: 1;
}

// Icon Status Nav Wizard

.sk-nav-vert.sk-nav-wizard .nav-link .step-icon {
  display: flex;
  align-items: center;
}
.sk-nav-vert.sk-nav-wizard .nav-link .step-icon i:before {
  font-family: 'Material Icons';
  font-size: 22px;
  font-weight: 500;
  color: $bgGray400;
  content: '\e3c9'; //edit
}
.sk-nav-vert.sk-nav-wizard .nav-link .step-icon.step-active i:before {
  color: $bgGray600;
  content: '\e3c9'; //edit
}
.sk-nav-vert.sk-nav-wizard .nav-link .step-icon.step-success i:before {
  color: $bgBlue600;
  content: '\e5ca'; //check
}
.sk-nav-vert.sk-nav-wizard .nav-link .step-icon.step-danger i:before {
  color: $bgRed500;
  content: '\e000'; //error_outline
}
.sk-nav-vert.sk-nav-wizard
  .nav-link:hover:not(.active)
  .step-icon:not(.step-danger)
  i:before {
  color: $bgGray600;
  content: '\e3c9'; //edit
}

/* Tabs Panel */

.sk-tabs-panel .nav-link {
  margin: 0 20px 0 0;
  padding: 0;
  line-height: 34px;
}
.sk-tabs-panel .nav-link.active,
.sk-tabs-panel .nav-link.active:hover {
  color: #222;
  font-weight: 500;
  box-shadow: inset 0 -2px $bgBlue500;
  -moz-box-shadow: inset 0 -2px $bgBlue500;
  -webkit-box-shadow: inset 0 -2px $bgBlue500;
}

/* Nav Basic */
.sk-nav-basic .nav-link.active {
  color: #222;
  font-weight: 500;
}

/* Steps - LEGACY */

.sk-steps .nav-link.active,
.sk-steps .nav-link.active:focus,
.sk-steps .nav-link.active:hover,
.sk-steps .nav-item.open .nav-link,
.sk-steps .nav-item.open .nav-link:focus,
.sk-steps .nav-item.open .nav-link:hover {
  box-shadow: inset 0 -2px $bgBlue400;
  -moz-box-shadow: inset 0 -2px $bgBlue400;
  -webkit-box-shadow: inset 0 -2px $bgBlue400;
  border-color: $borderGray500;
  background-color: transparent;
  color: $textNavHover;
  font-weight: 500;
  text-decoration: none;
}
.sk-steps .nav-link:hover {
  box-shadow: inset 0 -2px $bgBlue400;
  -moz-box-shadow: inset 0 -2px $bgBlue400;
  -webkit-box-shadow: inset 0 -2px $bgBlue400;
  color: $textNavHover;
}
.sk-steps {
  background-color: $bgGray50;
  border-top: 1px solid $borderGray400;
  border-bottom: 1px solid $borderGray400;
}
.sk-steps .nav-item .material-icons {
  position: relative;
  top: 10px;
  font-size: 32px;
  color: $bgGray400;
}
.sk-steps .nav-item + .nav-item {
  margin-left: 0.5rem;
}
.sk-steps .nav-link {
  border: none;
  border-bottom: 1px solid $borderGray400;
  color: $textNavSm;
  font-weight: 400;
  line-height: 54px;
  padding: 0;
}
.sk-steps .nav-link.disabled {
  pointer-events: none;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link.disabled:focus,
.nav-tabs .nav-link.disabled:hover {
  color: $textNavDisabled;
}

/* Full Width - LEGACY */

.sk-steps-full {
  background-color: $bgGray50;
  border-top: 1px solid $borderGray400;
  border-bottom: 1px solid $borderGray400;
  height: 56px;
}
.sk-steps-full .sk-steps {
  border: none;
}
.sk-steps-modal {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
  background-color: #ffffff;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid $borderGray400;
  height: 56px;
}
.sk-steps-modal .sk-steps {
  background-color: #ffffff;
  border: none;
}

/* PILLS GROUP */

.sk-pills {
  padding: 0;
  line-height: 32px;
}
.sk-pills .nav-link {
  border: 1px solid $borderGray700;
  color: $textNavSm;
  font-weight: 500;
  height: 34px;
  padding: 0 1rem;
}
.sk-pills .nav-item + .nav-item {
  margin-left: -1px;
}
.sk-pills .nav-link.active,
.sk-pills .nav-link.active:focus,
.sk-pills .nav-link.active:hover,
.sk-pills .nav-item.open .nav-link,
.sk-pills .nav-item.open .nav-link:focus,
.sk-pills .nav-item.open .nav-link:hover {
  background-color: $bgGray300;
  color: $textNavHover;
  cursor: pointer;
  font-weight: 500;
}
.sk-pills > .nav-item:first-child:not(:last-child) .nav-link {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.sk-pills > .nav-item:not(:first-child):not(:last-child) .nav-link {
  border-radius: 0;
}
.sk-pills > .nav-item:last-child:not(:first-child) .nav-link {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.sk-pills .nav-link:focus:not(.disabled):not(.active),
.sk-pills .nav-link:hover:not(.disabled):not(.active) {
  background-color: $bgGray100;
  border-color: $borderGray700;
  color: $textNavHover;
  text-decoration: none;
}
.sk-pills .nav-link.disabled,
.sk-pills .nav-link.disabled:focus,
.sk-pills .nav-link.disabled:hover {
  color: $textNavDisabled;
}
.nav-link:focus,
.nav-link:hover {
  outline: none;
  text-decoration: none;
}
.sk-pills .nav-link .md-22 {
  line-height: inherit;
  position: relative;
  top: -1px;
  opacity: 0.7;
  color: $textNavSm;
}
.sk-pills .nav-link.active .material-icons,
.sk-pills .nav-link:hover .material-icons {
  opacity: 1;
}
.sk-pills .nav-link.sk-nav-icon {
  padding: 0 10px;
}

/* BREADCRUMB */

.sk-breadcrumb {
  padding: 0;
  background-color: transparent;
  line-height: 34px;
  border: none;
  font-size: 13px;
}

/* Breadcrumb Wizard tabs */

.sk-tabs-panel.sk-wizard .nav-item.arrow {
  align-items: center;
  display: flex;
  margin: 0 5px;
}
.sk-tabs-panel.sk-wizard .nav-item.arrow i.material-icons {
  color: $bgGray400;
}
.sk-tabs-panel.sk-wizard .nav-link {
  margin: 0 0 0 0;
}
.sk-navbar-tertiary .sk-tabs-panel.sk-wizard .nav-link {
  line-height: 37px;
}

/* Breadcrumb Default */

.sk-breadcrumb {
  display: flex;
}
.sk-breadcrumb .breadcrumb-item {
  display: flex;
}
.sk-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  display: flex;
  align-items: center;
  color: $bgGray400;
  content: '\e315';
  font-family: 'Material Icons';
  font-size: 20px;
}
.breadcrumb-item.active {
  color: $textNavActive;
  font-weight: 500;
}

/* Breadcrumb Large */

.sk-breadcrumb-lg {
  font-size: 20px;
}
.sk-breadcrumb-lg a:not(.dropdown-item) {
  color: $textSecondary;
}
.sk-breadcrumb-lg .breadcrumb-item {
  height: 34px;
}
.sk-breadcrumb-lg .breadcrumb-item.active {
  color: $textHeading;
  font-weight: 500;
}
.sk-breadcrumb-lg > li + li::before,
.sk-breadcrumb-lg .breadcrumb-item + .breadcrumb-item::before {
  padding: 0;
  content: none;
}
.breadcrumb-item.arrow .material-icons {
  line-height: 34px;
  padding: 0 5px;
  color: $bgGray400;
}
