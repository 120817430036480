@import './variables';

/*---------------------
ACCORDION
----------------------*/
.accordion-button {
    font-size: inherit;
    font-weight: inherit;
    line-height: 34px;
}
.accordion-button:focus {
    outline: none;
    box-shadow: none; /* Remove box-shadow */
  }
.accordion-button:not(.collapsed) {
    box-shadow: 0px 1px 0px 0px $borderGray600;
}
.accordion-button::after {
    height: 12px;
}