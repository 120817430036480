
/*---------------------
BACKGROUND COLORS
----------------------*/
$bgWhite: #ffffff;

// Gray
$bgGray50: #f4f7fa; // .bg-light
$bgGray100: #eff2f6;
$bgGray200: #e9edf2; // .sk-nav-platform .panel.active
$bgGray300: #e0e6eb; // .badge-default
$bgGray400: #bac5ce; // .icon-light
$bgGray500: #a0abb6; // .badge-default-bold, .icon-muted
$bgGray600: #6e757d; // .icon-default, .material-icons
$bgGray700: #575c60;
$bgGray800: #414548; // .icon hover
$bgGray900: #343a40;

// Blue
$bgBlue50: #f0f9ff; // .table-hover tbody tr:hover, nav hover bg
$bgBlue100: #e1f1ff; // .table-hover tbody tr.active, .dropdown-item.active, nav active bg
$bgBlue200: #d3ebff; // .sk-panel-btn.active, .dropdown-item:hover
$bgBlue300: #a6ddff; // .sk-flag.flag-queued, .bg-queued
$bgBlue400: #24b6ff; // .nav-link.active, .progress-bar-info, .bg-primary
$bgBlue500: #2e9aff; // .icon-primary, .bg-primary
$bgBlue600: #0674db; // .icon-active, .btn-primary, .popover-dark
$bgBlue700: #0454a9; // .icon-active:hover
$bgBlue800: #03366d;
$bgBlue900: #2c3449; // cookies banner
$bgBlue950: #272c36; // modal bg

// Purple
$bgPurple100: #f5e5ff;
$bgPurple200: #e7c2ff;
$bgPurple300: #c694ff;
$bgPurple400: #a875f0;
$bgPurple500: #8e54e9;
$bgPurple600: #6635b1;

// Teal
$bgTeal100: #c8f9f6; // .alert-info, .badge-info (background)
$bgTeal200: #a3efee; // .badge-info, .sk-banner-block.alert-info
$bgTeal250: #71e5e1; // .badge-info.hover
$bgTeal300: #2cd9d3; // .icon-alternate
$bgTeal400: #00b7b4; // .icon-info
$bgTeal500: #008388; // .toast-info
$bgTeal600: #005661;

// Green
$bgGreen100: #c8f9ce; // .alert-success, .modal-header.sk-success (background)
$bgGreen200: #a6edc0; // .badge-success
$bgGreen300: #6fe297;
$bgGreen400: #40d875; // .bg-success, progress-bar-complete
$bgGreen500: #0eb43a; // .btn-success, .icon-success
$bgGreen500-hover: #0eb437; // btn-success :hover
$bgGreen500-active: #0ca633; // btn-success .active
$bgGreen600: #0b892c; // .toast-success

// Yellow
$bgYellow100: #fff7b8; // .alert-warning (background)
$bgYellow200: #fff08f; // .sk-banner-block.alert-warning
$bgYellow300: #ffd966; // .badge-warning
$bgYellow400: #ffc107; // .bg-warning
$bgYellow500: #f08800; // .icon-warning
$bgYellow600: #cc6900; // .toast-warning

// Red
$bgRed100: #ffdbdc; // .alert-danger (background)
$bgRed200: #ffcccd; // .badge-danger, .sk-banner-block.alert-danger
$bgRed250: #ffb3b5; // .badge-danger.hover
$bgRed300: #ff7579;
$bgRed400: #ff464b; // .bg-danger, .progress-bar-danger
$bgRed500: #e02e3d; // .invalid, .btn-danger, .icon-danger, badge-danger-bold
$bgRed500-hover: #d52a2a; // btn-danger :hover
$bgRed500-active: #d52a2a; // btn-danger .active
$bgRed600: #c21e2c; //  .toast-error

// Indigo
$bgIndigo400: #7c82d5;
$bgIndigo500: #5d60c6;
$bgIndigo600: #413ea8;

// Coral
$bgCoral400: #ff8f85;
$bgCoral500: #f66a60;
$bgCoral600: #d53e39;

// Magenta
$bgMagenta400: #e25aad;
$bgMagenta500: #d01b89;
$bgMagenta600: #a90067;

/*---------------------
BORDER COLORS
----------------------*/
// Gray
$borderGray300: #eff2f6;
$borderGray400: #e7ecf1; // table rows, page-header
$borderGray500: #dde2e6; // portal frame ui, hr, table head, nav-footer
$borderGray600: #d4d9dd; // card
$borderGray700: #c4cacf; // .dropdown-menu, .btn-outline-secondary, input
$borderGray800: #94a6bc; // .btn-outline-secondary :hover
$borderGray900: #8895a5; //

// Blue
$borderBlue100: #d2e4f9; // table row
$borderBlue200: #c5dcf6; // table row active
$borderBlue300: #93cafb;
$borderBlue400: #5ab0fc;
$borderBlue500: #1486f0; // input:hover:focus, card active
$borderBlue600: #076cca;

/*---------------------
TEXT COLORS
----------------------*/
$textBody: #1e2124;
$textHeading: #23282d;
$textDark: #343a40;
$textSecondary: #5e656e;
$textMuted: #6c757d; // 500
$textMeta: #75767a;
$textDisabled: #929aa0;
$textFormInput: #212529;
$textNavSm: #525860;
$textNavLg: #5e656e;
$textNavHover: #1b1c1d;
$textNavActive: #222;
$textNavDisabled: #929aa0;
$textLink: #056ed1;
$textLinkHover: #0351aa;
$textSuccess: #117e3b;
$textWarning: #c05600;
$textDanger: #c00716;
$textInfo: #187881;

/*---------------------
FORMS / INPUTS
----------------------*/
$formCheckBlue: #007deb;
$formCheckGray: #bdcbdb;
$formCheckDisabled: #ced6de;
$formCheckBlue50: #8fbbff;
$inputSuccess: #38cc69;
$inputSuccessFocus: #2eb25a;
$inputWarning: #f0a800;
$inputWarningFocus: #d89700;
$inputDanger: #fa6173;
$inputDangerFocus: #e8263c;

/*---------------------
BUTTON TEXT AND STATES
----------------------*/
$btnTextBlue: #056ed1; // same as $textLink
$btnTextBlueHover: #0351aa;
$btnTextDark500: #2f3337;
$btnTextDark600: #222;
$btnTextDisabled: #444;
$btnPrimary: #0674db; // same as $bgBlue600
$btnPrimaryHover: #0669c6;
$btnPrimaryActive: #0664bc;
$btnBgDisabled: #e3e7ea;
$btnBorderDisabled: #e3e7ea;

/*---------------------
COMPONENTS
----------------------*/
// Forms
// Checkbox, Radio
$form-check-input-width-sm:                  16px; // Custom

