@import './variables';

/*---------------------
TYPOGRAPHY
----------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  color: $textHeading;
  margin-bottom: 0;
}
h1,
.h1 {
  font-size: 28px;
  line-height: 38px;
}
h2,
.h2 {
  font-size: 24px;
  line-height: 32px;
}
h3,
.h3 {
  font-size: 20px;
  line-height: 24px;
}
h4,
.h4 {
  font-size: 16px;
  line-height: 22px;
}
h5,
.h5 {
  font-size: 14px;
  line-height: 20px;
}
h6,
.h6 {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 20px;
}
h1,
.h1,
h2,
.h2 {
  color: $textHeading;
}
.display-6 {
  font-size: 48px;
  font-weight: 300;
}
.display-5 {
  font-size: 44px;
  font-weight: 300;
}
.display-4 {
  font-size: 36px;
  font-weight: 400;
}
.display-3 {
  font-size: 32px;
  font-weight: 400;
}
.display-2 {
  font-size: 28px;
  font-weight: 400;
}
.display-1 {
  font-size: 24px;
  font-weight: 400;
}
mark,
.mark {
  background-color: $bgYellow200;
}
small,
.small {
  font-size: 12px;
  line-height: 16px;
}
code,
.code {
  color: #d91a72;
  font-size: 12px;
  font-family: 'Roboto Mono', SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace !important;
}
.font-monospace {
  font-family: 'Roboto Mono', SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace !important;
  font-size: 12px;
}

/* Body */

.body-display-2,
.sk-body-display-2,
.sk-body-display {
  font-size: 15px;
  line-height: 24px;
  color: $textBody;
}
.body-display-1,
.sk-body-display-1 {
  font-size: 14px;
  line-height: 22px;
  color: $textBody;
}

/*---------------------
LISTS
----------------------*/

ul.sk-ul {
  padding-left: 1.5rem;
  list-style-type: disc;
}
ul.sk-ul li {
  padding-bottom: 2px;
}
ul.sk-ul ul.sk-ul {
  padding-top: 2px;
  margin-bottom: -2px;
}

// Description List
dl dd {
  margin-bottom: 4px;
}
dt {
  color: $textDark;
}
// One column
dl.sk-dl dt:not(:first-child) {
  padding-top: 6px;
}
// Two column
dl.list-light dt {
  color: $textMuted;
  font-weight: 400;
}
dl.list-light dd {
  margin-bottom: 0;
}
