@import './variables';

/*---------------------
TABLES
----------------------*/
.table {
  color: $textBody;
}
.table thead th,
.table td {
  line-height: 28px;
  vertical-align: middle;
}
.table thead th {
  color: $textSecondary;
  background-color: $bgWhite;
  border-bottom: none;
  border-top: none;
  height: 34px;
  line-height: 34px;
  font-weight: 400;
  font-size: 12px;
  padding-left: 1rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1rem;
}

.table tr:first-child td {
  border-top: 1px solid $borderGray600;
}
.table tr td.primary {
  font-weight: 500;
}

// UPDATED ROW ACTIVE STATE
/* ACTIVE ROW TAB STYLE */
.sk-table-tabs > tbody > tr.table-active:hover td{
  box-shadow: inset 0 0 0 9999px $bgBlue50;
}
.sk-table-tabs > tbody > tr.table-active > td:first-child {
  box-shadow: inset 3px 0 $bgBlue500;
  background-color: $bgBlue50;
}

/* LINKS BLUE HOVER STATE  */
.table.sk-link-hover td a {
  color: inherit;
}
.table.sk-link-hover tr:hover a {
  color: $textLink;
  cursor: pointer;
}
.table.sk-link-hover tr:hover a:hover {
  color: $textLinkHover;
  cursor: pointer;
  text-decoration: underline;
}

/* STANDARDIZE */
.sk-standard-table td {
  line-height: 48px;
  padding: 0 1rem;
  white-space: nowrap;
}
.sk-table-cap .sk-table-title {
  vertical-align: middle;
  margin-bottom: 1rem;
}
.sk-table-title h3 {
  line-height: 34px;
}
.sk-table-cap h4,
.sk-table-cap h5 {
  line-height: 34px;
}

/* Standard + Wrap */
.sk-standard-wrap-table td {
  height: 48px;
  line-height: 15px;
  padding: 6px 1rem;
}

/* BASIC COMPACT */

.sk-basic-table thead th {
  background: transparent;
}
.sk-compact-table,
.sk-compact-table thead th,
.sk-compact-table tr td {
  padding: 0;
  margin-bottom: 0;
  line-height: 28px;
}

/* WRAPPING */

.sk-wrap-table thead th {
  line-height: 20px;
  padding: 1.1rem 0.75rem;
}
.sk-wrap-table td {
  line-height: 20px;
}

/* SELECT */

.sk-select-table thead th:first-child,
.sk-select-table td:first-child {
  width: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.sk-select-table thead th:nth-child(2),
.sk-select-table td:nth-child(2) {
  padding-left: 0rem;
}
.sk-select-table .checkbox label {
  padding-left: 18px;
}

/* ACTIVE */

.sk-th-active thead th {
  background-color: #f6fbff;
  border-top-color: #87c1f5;
}
.sk-th-active tr:first-child td {
  border-top-color: #87c1f5;
}

/* FLAG TABLE */

.sk-flag-table tr td.sk-flag-td {
  padding-left: 0;
  padding-right: 0;
  width: 18px;
}
.sk-flag-table tr td.primary,
.sk-flag-table tr th.primary {
  padding-left: 0;
}
.sk-flag-table thead th:first-child,
.sk-flag-table td:first-child {
  padding-right: 5px;
}

/* ACCORDION */

.sk-accordion-table tr td.sk-arrow-btn {
  padding-left: 0;
  padding-right: 0;
  width: 38px;
}
.sk-accordion-table tr.child-row td {
  border-top: none;
  padding: 0;
  white-space: normal;
}
.sk-accordion-table tr.child-row .sk-msg-content {
  background-color: #f4f7fa;
  box-shadow:
    inset 0px 2px 2px -2px rgba(91, 102, 111, 0.6),
    inset 0px -2px 2px -2px rgba(91, 102, 111, 0.6);
  line-height: 20px;
  padding: 2rem 10rem 3rem 54px;
  position: relative;
}
.sk-accordion-table .sk-msg-content .sk-msg-table {
  width: 100%;
}
.sk-accordion-table .sk-msg-content .sk-msg-table td {
  line-height: 28px;
  vertical-align: middle;
  border-top: 1px solid #e7ecf1;
}
.sk-accordion-table .sk-msg-subject {
  padding-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 610px;
}
.sk-accordion-table tr.child-row .sk-msg-content .sk-msg-footer {
  background: #fff;
  bottom: -1px;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.sk-accordion-table td.sk-arrow-toggle-1,
.sk-accordion-table td.sk-arrow-toggle-2 {
  cursor: pointer;
}
.sk-accordion-table > tbody > tr:last-child td {
  box-shadow: 0px 1px 0px #6c6f71;
}
.sk-accordion-table tr.parent-row.active {
  font-weight: 500;
}
.sk-accordion-table td.sk-arrow-btn i.sk-arrow-up {
  display: none;
}

/* CHILD */

.sk-child-table tr.parent-row td:first-child {
  padding-left: 7px;
  font-weight: 500;
}
.sk-child-table tr.child-row td:first-child {
  padding-left: 4rem;
}
tr.child-row .sk-flag {
  width: 10px;
  height: 10px;
}
.sk-child-table .sk-flag {
  margin-right: 4px;
}

/* SORTABLE */

/* Default columns not sortable */

.sk-sortable.table thead th,
.sk-sortable.table thead th:hover {
  background-color: $bgGray100;
  cursor: default;
}
.sk-sortable.table thead th.sortable:hover,
.sk-sortable.table thead th.sorted.ascending:hover,
.sk-sortable.table thead th.sorted.descending:hover {
  color: #282d31;
  cursor: pointer;
}
.sk-sortable.table thead th.sorted.ascending,
.sk-sortable.table thead th.sorted.descending {
  background-color: $bgGray100;
  color: $textBody;
}
th.sorted.ascending:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5rem;
  vertical-align: middle;
  content: '';
  border-bottom: 0.34em solid;
  border-right: 0.34em solid transparent;
  border-left: 0.34em solid transparent;
  margin-top: -2px;
}
th.sorted.descending:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5rem;
  vertical-align: middle;
  content: '';
  border-top: 0.34em solid;
  border-right: 0.34em solid transparent;
  border-left: 0.34em solid transparent;
  margin-top: -2px;
}

/* DISABLED/REMOVED ROW */

.table tr.disabled {
  background: #f9fafb;
  color: #999;
}
.table tr.disabled:hover {
  background: #f9fafb;
}
.table tr.disabled a {
  color: #666;
}
.table-hover tbody tr.disabled:hover td {
  border-color: #e7ecf1;
}
.table-hover tbody tr.disabled:hover + tr td {
  border-top-color: #e7ecf1;
}

/* DOUBLE STACK */

.table tr td.sk-stack {
  line-height: 18px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #484a4d;
}
td.sk-stack h4 {
  color: #484a4d;
}
td.sk-stack .sk-stacked {
  padding-top: 0.5rem;
}

/* NESTED */

td.sk-nest-table {
  border: 1px solid #a3d4ff;
  padding: 0;
}
.sk-nest-table > table {
  padding: 0;
  width: 100%;
}
.sk-nest-table thead th {
  color: #5f6c79;
  background-color: #fff;
  border-bottom: none;
}

/* DASHBOARD TABLE */

.table tr td.sk-progress {
  width: 150px;
}
td.sk-progress .progress {
  margin-bottom: 0;
  margin-top: 2px;
  max-width: 7rem;
}
.table tr td.sk-progress.sk-compact {
  width: 6rem;
}
td.sk-progress.sk-compact .progress {
  max-width: 5rem;
}
td.sk-progress .material-icons,
td.sk-icon-solo .material-icons {
  vertical-align: middle;
  cursor: default;
}
.table tr.child-row td {
  border-top-color: #f2f4f7;
}
.child-row .sk-stack h5 {
  font-size: 13px;
}
td.sk-progress .sk-flag {
  width: 8px;
  height: 8px;
  margin-bottom: 6px;
  margin-left: 6px;
}
.sk-dash-key {
  font-size: 12px;
  color: #818a91;
}
.sk-dash-key .sk-key {
  padding: 0 1rem 0 4px;
  line-height: 34px;
  border-left: 4px solid #ff0000;
}
.sk-dash-key .sk-key-preparing {
  border-color: #a6e2ff;
}
.sk-dash-key .sk-key-ready {
  border-color: #79a0f1;
}
.sk-dash-key .sk-key-complete {
  border-color: #50e3c2;
}
.sk-dash-key .sk-key-attention {
  border-color: #ff464b;
}

/* THEAD INFO ROW */

.table tr.sk-thead-info td {
  line-height: 1.8;
  font-size: 11px;
  text-align: center;
  background-color: #fff;
  border-top: none;
  font-weight: 500;
  border-bottom: 2px solid $borderGray500;
}
.table tr.sk-thead-info td.sk-info-span {
  color: #a4afb7;
  border-bottom: 2px solid #a4b2bd;
  border-left: 2px solid #fff;
}
.table tr.sk-thead-info td.sk-info-span:not(:last-child) {
  border-right: 2px solid #fff;
}
th.sk-border-left {
  border-left: 1px solid $borderGray500;
}
th.sk-border-right {
  border-right: 1px solid $borderGray500;
}
td.sk-border-left {
  border-left: 1px solid $borderGray400;
}
td.sk-border-right {
  border-right: 1px solid $borderGray400;
}

/* PLAN SELECTOR TABLE */

.plan-table-scroll {
  overflow-x: scroll;
  margin-left: 200px;
  border-right: 1px solid $borderGray400;
  scroll-behavior: smooth;
}
.sk-plan-table {
  margin-bottom: 0;
}
.sk-plan-table td {
  min-width: 200px;
  max-width: 200px;
  padding: 6px 13px;
}
.sk-plan-table td.col-fixed {
  position: absolute;
  left: 0;
  border-left: 1px solid $borderGray400;
  margin-top: -1px;
}
.sk-plan-table td {
  border-right: 1px solid $borderGray400;
}
.sk-plan-table .table-active {
  font-weight: 600;
  background-color: $bgBlue100;
  box-shadow:
    inset 1px 0 0 $borderBlue500,
    inset -1px 0 0 $borderBlue500;
}
.sk-plan-table .table-active.td-last {
  box-shadow:
    inset 1px 0 0 $borderBlue500,
    inset -1px 0 0 $borderBlue500,
    inset 0 -1px 0 $borderBlue500;
}
.sk-plan-table .table-active.td-first {
  box-shadow:
    inset 1px 0 0 $borderBlue500,
    inset -1px 0 0 $borderBlue500,
    inset 0 1px 0 $borderBlue500;
}

/* PAGINATION */

.sk-pagination {
  display: flex;
  justify-content: space-between;
}
.sk-view,
.sk-results,
.sk-pagination ul.pagination {
  flex: 1 100%;
}
.sk-pagination .sk-view {
  display: flex;
  justify-content: flex-end;
}
.sk-results {
  text-align: left;
}
.sk-pagination ul.pagination {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.pagination {
  margin-top: 0;
}
.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  color: #1b1c1d;
  background-color: #f3f7fb;
  border-color: #bdcbdb;
  font-weight: 600;
}
.page-link {
  border-color: #bdcbdb;
  color: #a1abb3;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.page-link:focus,
.page-link:hover {
  background-color: #d4ebff;
  border-color: #bdcbdb;
  color: #3b4954;
  text-decoration: none;
}
.page-item.disabled .page-link,
.page-item.disabled .page-link:focus,
.page-item.disabled .page-link:hover {
  border-color: #bdcbdb;
}
.page-item.disabled .sk-page-arrow .md-22 {
  opacity: 0.3;
}
.sk-page-arrow .md-22 {
  color: inherit;
}
nav.sk-pagination .sk-page-arrow {
  padding: 0 7px;
  line-height: 32px;
  border-color: #bdcbdb;
}
nav.sk-pagination .sk-page-arrow:hover {
  background-color: #e2f1ff;
  border-color: #bdcbdb;
}
nav.sk-pagination .sk-page-arrow:active,
nav.sk-pagination .sk-page-arrow:focus {
  background-color: #d4ebff;
  border-color: #bdcbdb;
  outline: none;
}
nav.sk-pagination .sk-page-arrow:active .md-22 {
  color: rgba(74, 82, 93, 1);
}
nav.sk-pagination .page-item.disabled .sk-page-arrow {
  border-color: #d7e3ef;
}
nav.sk-pagination .md-22 {
  vertical-align: middle;
  line-height: 31px;
}
nav .sk-results {
  display: inline-block;
  vertical-align: middle;
  color: #818a91;
  line-height: 34px;
}
nav .sk-view {
  display: inline-block;
  line-height: 34px;
}

/* TABLE UTILITIES */
.table .thead-white th {
  background-color: #fff !important;
}
.table .thead-transparent th {
  background-color: transparent;
}
.table .thead-border-none th {
  border-top: none;
}

/* Override hover cascade */
.table-hover-none tbody tr:hover {
  background-color: transparent !important;
}
