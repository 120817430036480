@import './variables';

/*---------------------
CARDS
----------------------*/

.card {
  border: 1px solid $borderGray600;
  margin-bottom: 0;
}
.card-inverse .card-content,
.card-inverse .card-content .material-icons {
  color: #ffffff;
}
.card-header {
  background-color: $bgGray50;
  border-bottom: 1px solid $borderGray600;
  font-weight: 500;
}
.card-footer {
  background-color: transparent;
  border-top: 1px solid $borderGray600;
}
.card-header .md-16,
.card-header .md-18 {
  vertical-align: sub;
  padding-right: 0.5rem;
}
.card-header a:hover {
  text-decoration: none;
  cursor: pointer;
}
.card-block {
  padding: 1rem 1.25rem;
}
.card-block.sk-btn-row,
.card-footer.sk-btn-row {
  padding-top: 5px;
  padding-bottom: 5px;
}
.list-group-item .md-18 {
  vertical-align: sub;
  padding-right: 0.5rem;
}
.sk-card {
  background-color: #ffffff;
  transition-duration: 0.2s;
  transition-property: box-shadow, transform;
}

/* Card Hover */

.card-hover:hover {
  border: 1px solid $borderBlue500;
}
.card-hover-inset:hover {
  box-shadow: inset 0 0 1px 0 $borderBlue500;
}
.card-hover:hover .link-hover {
  color: #056ed1;
  cursor: pointer;
}

/* Card Active */

.card-hover.active {
  border: 1px solid $borderBlue500;
  background-color: $bgBlue50;
}

/* STANDARD CARD HEADER */

.sk-card-header {
  vertical-align: middle;
  padding: 5px 1.25rem;
  background: transparent;
}
.sk-standard-card .sk-card-header {
  background-color: $bgGray50;
}
.sk-card-header h3,
.sk-card-header h4,
.sk-card-header h5 {
  line-height: 34px;
}
.sk-card-header .sk-card-img {
  vertical-align: middle;
  height: 34px;
}
.sk-card-header .sk-onoffswitch {
  margin-top: 2px;
}
.sk-flush .list-group-item:first-child {
  border-top: 0;
}
.sk-compact.card-block,
.sk-compact.card-header,
.sk-compact.card-footer {
  padding-top: 0;
  padding-bottom: 0;
}
.card-header .nav {
  border-bottom: none;
}
a .card-header.sk-card-header:hover i.material-icons {
  color: #111;
}

/*---------------------
LIST GROUP
----------------------*/
.list-group-item {
  border: 1px solid #d4d9dd;
  padding: 9px 1.25rem;
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  color: #373a3c;
  background-color: $bgGray50;
  border-color: #e2e6ea;
  font-weight: 600;
}
a.list-group-item {
  color: #6b7784;
  font-weight: 500;
}
a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  color: #2b2d2f;
  background-color: $bgGray50;
}
.list-group-header {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}