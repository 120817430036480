@import './variables';

/*---------------------
MODAL
----------------------*/

.modal-content {
  word-break: break-word;
  overflow-wrap: break-word;
}
.modal-header {
  background-color: $bgGray100;
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}
.modal-header .btn-close {
  padding: $modal-inner-padding;
}
.modal-header.sk-success:before,
.modal-header.sk-warning:before,
.modal-header.sk-danger:before {
  font-family: 'Material Icons';
  font-size: 22px;
  font-weight: 500;
  margin-right: 6px;
  line-height: 24px;
}
.modal-header.sk-success {
  background-color: $bgGreen100;
}
.modal-header.sk-success:before {
  color: $bgGreen500;
  content: '\e86c'; // Material Icon: check (circle)
}
.modal-header.sk-warning {
  background-color: $bgYellow100;
}
.modal-header.sk-warning:before {
  color: $bgYellow500;
  content: '\e000';
}
.modal-header.sk-danger {
  background-color: $bgRed100;
}
.modal-header.sk-danger:before {
  color: $bgRed500;
  content: '\e002';
}

.modal-footer > :not(:last-child) {
  margin-right: 0.5rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.5rem;
}
/*---------------------
MODAL PANEL
----------------------*/

.modal-panel.modal-left .modal-dialog,
.modal-panel.modal-right .modal-dialog {
  position: fixed;
  top: 0;
  margin: auto;
  height: 100%;
  transform: translate(0, 0);
}
.modal-panel .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
}
.modal-panel .modal-header {
  padding: 1rem 1.5rem;
  align-items: center;
}
.modal-panel .modal-title {
  line-height: 34px;
}
.modal-panel .modal-body {
  padding: 1.5rem;
  overflow: auto;
}

/* Right Modal-Panel */
.modal-panel.modal-right.fade .modal-dialog {
  right: -6rem; // animate from-to
  transition: right 0.2s ease-out;
}
.modal-panel.modal-right.show .modal-dialog {
  right: 0; // justify right
}

/* Left Modal-Panel */
.modal-panel.modal-left.fade .modal-dialog {
  left: -6rem; // animate from-to
  transition: left 0.2s ease-out;
}
.modal-panel.modal-left.show .modal-dialog {
  left: 0; // justify left
}

/* Modal-Panel Sizes */
.modal-panel .modal-dialog {
  // Default Medium
  width: 38rem; // 38=494px
}
.modal-panel .modal-sm {
  width: 29rem !important; // 29=377px
  max-width: 100%;
}
.modal-panel .modal-lg {
  width: 58rem; // 56=728px
}
.modal-panel .modal-xl {
  width: 78rem; // 78=1014px
}

/*---------------------
FULLSCREEN
----------------------*/

.modal.modal-fullscreen .modal-dialog {
  margin: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
}
.modal.modal-fullscreen .modal-dialog .modal-content {
  border: none;
  height: 100%;
}
