/*---------------------
AVATAR
----------------------*/

.avatar-bg-1 {
  background-color: #9772d9 !important;
}
.avatar-bg-2 {
  background-color: #00b3aa !important;
}
.avatar-bg-3 {
  background-color: #6d75ef !important;
}
.avatar-bg-4 {
  background-color: #f58b00 !important;
}
.avatar-bg-5 {
  background-color: #ba31bf !important;
}
.avatar-bg-6 {
  background-color: #3889a8 !important;
}
.avatar-bg-7 {
  background-color: #d16830 !important;
}
.avatar-bg-8 {
  background-color: #d65087 !important;
}
.avatar-bg-9 {
  background-color: #5f99e0 !important;
}
.avatar-bg-10 {
  background-color: #609c21 !important;
}

/* Standard (Medium) */

.sk-user-avatar {
  border-radius: 50%;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  width: 32px;
  display: inline-block;
}
.sk-user-avatar.dropdown-toggle {
  cursor: pointer;
}
.sk-user-avatar .avatar-initials {
  background-color: #dae0e7;
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 600;
  justify-content: center;
  text-transform: uppercase;
}
.sk-user-avatar img {
  height: 32px;
  width: 32px;
  display: block;
}

/* Small */

.sk-user-avatar.avatar-sm {
  height: 24px;
  width: 24px;
  line-height: 24px;
}
.sk-user-avatar.avatar-sm img {
  height: 24px;
  width: 24px;
}
.sk-user-avatar.avatar-sm .avatar-initials {
  font-size: 12px;
}

/* Large */

.sk-user-avatar.avatar-lg {
  height: 48px;
  width: 48px;
  line-height: 48px;
}
.sk-user-avatar.avatar-lg img {
  height: 48px;
  width: 48px;
}
.sk-user-avatar.avatar-lg .avatar-initials {
  font-size: 22px;
  font-weight: 500;
}

/* Extra Large */

.sk-user-avatar.avatar-xl {
  height: 72px;
  width: 72px;
  line-height: 72px;
}
.sk-user-avatar.avatar-xl img {
  height: 72px;
  width: 72px;
}
.sk-user-avatar.avatar-xl .avatar-initials {
  font-size: 32px;
  font-weight: 500;
}
