@import './variables';

/*---------------------
FLAG STATUS
----------------------*/
.sk-flag {
  border-radius: 50%;
  border: 1px solid #ffffff;
  display: inline-block;
  height: 13px;
  width: 13px;
  margin-top: -1px;
  vertical-align: middle;
}
.sk-flag.flag-none,
.sk-flag.none {
  background-color: transparent;
  border: none;
}
.sk-flag.flag-default {
  background-color: $bgGray300;
}
.sk-flag.flag-success,
.sk-flag.flag-complete {
  // Deprecate .flag-complete
  background-color: $bgGreen500;
}
.sk-flag.flag-danger,
.sk-flag.red,
.sk-flag.flag-attention {
  // Deprecate .flag-attention, .flag-red
  background-color: $bgRed500;
}
.sk-flag.flag-warning {
  background-color: $bgYellow500;
}
.sk-flag.flag-queued,
.sk-flag.flag-preparing {
  // Deprecate .flag-preparing
  background-color: $bgBlue300;
}
.sk-flag.flag-active,
.sk-flag.flag-ready {
  // Deprecate .flag-ready
  background-color: $bgBlue400;
}

/*---------------------
PROGRESS
----------------------*/

.progress {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: $bgGray200;
  border-radius: 3px;
}
.progress-bar {
  background-color: $bgBlue400;
  color: #fff;
  height: 1rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Contextual */

.progress .progress-bar:not(:first-child) {
  border-left: 1px solid #fff;
}
.progress-bar.text-bg-danger {
  background-color: $bgRed400 !important;
}
.progress-bar.text-bg-queued {
  background-color: $bgBlue300 !important;
}
.progress-bar.disabled {
  background-color: $bgGray400;
}

/* Validation - Danger Icon */

.progress-group .status-label {
  display: block;
}
.progress-group .status-label:before {
  font-family: 'Material Icons';
  font-size: 20px;
  float: left;
  margin-left: -26px;
  line-height: 20px;
}
.progress-group.has-danger .status-label {
  color: $textDanger;
  padding-left: 26px;
}
.progress-group.has-danger .status-label:before {
  color: #e02e3d;
  content: '\e002';
}

/* Stripes Animated */

.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 20%,
    transparent 10%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 20%,
    transparent 10%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 20%,
    transparent 10%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 1rem 1rem;
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  -o-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

/*---------------------
SPINNER
----------------------*/

.sk-spin-loader {
  content: '';
  font-size: 3px;
  position: relative;
  text-indent: 100%;
  border-top: 6px solid $bgBlue200;
  border-right: 6px solid $bgBlue200;
  border-bottom: 6px solid $bgBlue200;
  border-left: 6px solid $bgBlue400;
  -webkit-animation: load8 0.8s infinite linear;
  animation: load8 0.8s infinite linear;
  overflow: hidden;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}
.sk-spin-loader.loader-md {
  border-width: 5px;
  width: 3rem;
  height: 3rem;
}
.sk-spin-loader.loader-sm {
  border-width: 4px;
  width: 2rem;
  height: 2rem;
}
.sk-spin-loader.loader-light {
  border-top: 4px solid rgba(255, 255, 255, 0.3);
  border-right: 4px solid rgba(255, 255, 255, 0.3);
  border-bottom: 4px solid rgba(255, 255, 255, 0.3);
  border-left: 4px solid rgba(255, 255, 255, 1);
}
.sk-spin-loader.loader-dark {
  border-top: 4px solid $borderGray600;
  border-right: 4px solid $borderGray600;
  border-bottom: 4px solid $borderGray600;
  border-left: 4px solid $bgGray600;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*---------------------
BUTTON SPINNER
----------------------*/

.sk-btn-spinner {
  display: inline-flex;
  opacity: 0;
  width: 0;
  vertical-align: sub;
}
.sk-btn-spinner-fixed {
  display: inline-flex;
  opacity: 0;
  width: 0;
  vertical-align: sub;
}
.btn .sk-btn-spinner .sk-spin-loader,
.btn .sk-btn-spinner-fixed .sk-spin-loader {
  width: 20px;
  height: 20px;
}
.btn-lg .sk-btn-spinner .sk-spin-loader,
.btn-lg .sk-btn-spinner-fixed .sk-spin-loader {
  width: 2rem;
  height: 2rem;
  position: relative;
  top: 3px;
}
.sk-has-spinner.active {
  cursor: default;
}
.btn.sk-has-spinner.active .sk-btn-spinner {
  opacity: 1;
  width: 20px;
  margin-right: 0.6rem;
}
.btn.sk-has-spinner.active .sk-btn-spinner-fixed {
  opacity: 1;
  width: 20px;
  margin-right: 0rem;
}
.btn-lg.sk-has-spinner.active .sk-btn-spinner {
  width: 2rem;
  margin-right: 0.8rem;
}
.btn-lg.sk-has-spinner.active .sk-btn-spinner-fixed {
  width: 2rem;
  margin-right: 0rem;
}
.sk-loader-label {
  display: inline-flex;
}
.sk-loader-label.sk-loading {
  display: inline-flex;
  opacity: 0;
  width: 0;
}
.sk-has-spinner.active .sk-loader-label.sk-loading {
  opacity: 0.8;
  display: inline-flex;
}
.sk-has-spinner.active .sk-loader-label {
  display: none;
}
.sk-has-spinner.active,
.sk-has-spinner.active:disabled {
  color: #b3b3b3;
  opacity: 1;
  background-color: #f1f3f5;
  border-color: #f1f3f5;
}
.sk-btn-icon.sk-has-spinner.active:hover {
  color: #b3b3b3;
  opacity: 1;
  background-color: #f1f3f5;
  border-color: #f1f3f5;
}

/* ABSOLUTE SPINNER */

.sk-abs-spinner.active .sk-loader-label {
  opacity: 0;
  display: inline-flex;
}
.btn.sk-abs-spinner {
  position: relative;
}
.btn.sk-abs-spinner .sk-btn-spinner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  pointer-events: none;
}
.btn-lg.sk-abs-spinner .sk-btn-spinner {
  margin-top: -16px;
  margin-left: -13px;
}

/* ABSOLUTE SPINNER */

.sk-modal-spinner {
  width: 4rem;
  margin: 0 auto;
}

/* SPINNER UPDATE */

.btn.sk-has-spinner .sk-spin-loader {
  animation: none;
}
.btn.sk-has-spinner.active .sk-spin-loader {
  -webkit-animation: load8 0.8s infinite linear;
  animation: load8 0.8s infinite linear;
}
