@import './variables';

// Bootstrap v4 duplicate utilities included for v4-alpha legacy portal UI

/*---------------------
BORDERS
----------------------*/

hr {
  border-top-color: $borderGray500;
}
.border {
  border: 1px solid $borderGray500 !important;
}
.border-top {
  border-top: 1px solid $borderGray500 !important;
}
.border-right {
  border-right: 1px solid $borderGray500 !important;
}
.border-bottom {
  border-bottom: 1px solid $borderGray500 !important;
}
.border-left {
  border-left: 1px solid $borderGray500 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}

/* Border Colors Gray */

.border-gray-300,
.b-gray-3 {
  border-color: $borderGray300 !important;
}
.border-gray-400,
.b-gray-4 {
  border-color: $borderGray400 !important;
}
.border-gray-500,
.b-gray-5 {
  border-color: $borderGray500 !important;
}
.border-gray-600,
.b-gray-6 {
  border-color: $borderGray600 !important;
}
.border-gray-700,
.b-gray-7 {
  border-color: $borderGray700 !important;
}
.border-gray-800,
.b-gray-8 {
  border-color: $borderGray800 !important;
}

/* Border Colors Blue */

.border-blue-100 {
  border-color: $borderBlue100 !important;
}
.border-blue-200 {
  border-color: $borderBlue200 !important;
}
.border-blue-300 {
  border-color: $borderBlue300 !important;
}
.border-blue-400 {
  border-color: $borderBlue400 !important;
}
.border-blue-500 {
  border-color: $borderBlue500 !important;
}
.border-blue-600 {
  border-color: $borderBlue600 !important;
}

/* Shadows  */
.shadow-inset-y {
  box-shadow:
    inset 0px 2px 2px -2px rgba(91, 102, 111, 0.6),
    inset 0px -2px 2px -2px rgba(91, 102, 111, 0.6);
}
.shadow-bottom-1 {
  box-shadow: 0px 1px $borderGray500;
}

/*---------------------
COLORS
----------------------*/

/* Colors Neutral */

.bg-light,
.bg-gray-50 {
  background-color: $bgGray50 !important;
}
.bg-gray-100 {
  background-color: $bgGray100 !important;
}
.bg-gray-200 {
  background-color: $bgGray200 !important;
}
.bg-gray-300 {
  background-color: $bgGray300 !important;
}
.bg-gray-400 {
  background-color: $bgGray400 !important;
}
.bg-gray-500 {
  background-color: $bgGray500 !important;
}
.bg-gray-600 {
  background-color: $bgGray600 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}

/* Colors Blue */

.bg-blue-50 {
  background-color: $bgBlue50 !important;
}
.bg-blue-100 {
  background-color: $bgBlue100 !important;
}
.bg-blue-200 {
  background-color: $bgBlue200 !important;
}
.bg-blue-300 {
  background-color: $bgBlue300 !important;
}
.bg-blue-400 {
  background-color: $bgBlue400 !important; // same as .bg-primary
}
.bg-blue-500 {
  background-color: $bgBlue500 !important;
}
.bg-blue-600 {
  background-color: $bgBlue600 !important;
}
.bg-blue-700 {
  background-color: $bgBlue700 !important;
}
.bg-blue-800 {
  background-color: $bgBlue800 !important;
}

/* Colors Accent */
.bg-coral-400 {
  background-color: $bgCoral400 !important;
}
.bg-coral-500 {
  background-color: $bgCoral500 !important;
}
.bg-coral-600 {
  background-color: $bgCoral600 !important;
}
.bg-indigo-400 {
  background-color: $bgIndigo400 !important;
}
.bg-indigo-500 {
  background-color: $bgIndigo500 !important;
}
.bg-indigo-600 {
  background-color: $bgIndigo600 !important;
}
.bg-magenta-400 {
  background-color: $bgMagenta400 !important;
}
.bg-magenta-500 {
  background-color: $bgMagenta500 !important;
}
.bg-magenta-600 {
  background-color: $bgMagenta600 !important;
}
.bg-purple-400 {
  background-color: $bgPurple400 !important;
}
.bg-purple-500 {
  background-color: $bgPurple500 !important;
}
.bg-purple-600 {
  background-color: $bgPurple600 !important;
}

/* Colors Contextual */

/* Color Teal/Info */
.bg-info-light,
.bg-teal-100 {
  background-color: $bgTeal100 !important;
}
.bg-teal-200 {
  background-color: $bgTeal200 !important;
}
.bg-teal-300 {
  background-color: $bgTeal300 !important;
}
.bg-info,
.bg-teal-400 {
  background-color: $bgTeal400 !important;
}
.bg-teal-500 {
  background-color: $bgTeal500 !important;
}
.bg-teal-600 {
  background-color: $bgTeal600 !important;
}

/* Color Green/Success */
.bg-success-light,
.bg-green-100 {
  background-color: $bgGreen100 !important;
}
.bg-green-200 {
  background-color: $bgGreen200 !important;
}
.bg-green-300 {
  background-color: $bgGreen300 !important;
}
.bg-success,
.bg-green-400 {
  background-color: $bgGreen400 !important; // use for progress-bar or graph
}
.bg-green-500 {
  background-color: $bgGreen500 !important;
}
.bg-green-600 {
  background-color: $bgGreen600 !important;
}

/* Color Yellow/Warning */
.bg-warning-light,
.bg-yellow-100 {
  background-color: $bgYellow100 !important;
}
.bg-yellow-200 {
  background-color: $bgYellow200 !important;
}
.bg-yellow-300 {
  background-color: $bgYellow300 !important;
}
.bg-warning,
.bg-yellow-400 {
  background-color: $bgYellow400 !important; // use for progress-bar or graph
}
.bg-yellow-500 {
  background-color: $bgYellow500 !important;
}
.bg-yellow-600 {
  background-color: $bgYellow600 !important;
}

/* Color Red/Danger */
.bg-danger-light,
.bg-red-100 {
  background-color: $bgRed100 !important;
}
.bg-red-200 {
  background-color: $bgRed200 !important;
}
.bg-red-300 {
  background-color: $bgRed300 !important;
}
.bg-danger,
.bg-red-400 {
  background-color: $bgRed400 !important; // use for progress-bar or graph
}
.bg-red-500 {
  background-color: $bgRed500 !important;
}
.bg-red-600 {
  background-color: $bgRed600 !important;
}

/* Color Progress */
.bg-primary,
.bg-ready {
  // Deprecate .bg-ready
  background-color: $bgBlue400 !important;
}
.bg-queued,
.bg-preparing {
  // Deprecate .bg-preparing
  background-color: $bgBlue300 !important;
}
.bg-default {
  background-color: $bgGray300 !important;
}

/*---------------------
ICONS
----------------------*/

.material-icons,
.material-icons-outlined {
  color: $bgGray600;
}
.material-icons.md-blue,
.material-icons-outlined.md-blue {
  color: $bgBlue600;
}
.material-icons.md-gray,
.material-icons-outlined.md-gray {
  color: $bgGray500;
}
.material-icons.md-inactive,
.material-icons-outlined.md-inactive {
  color: $bgGray300;
}
.material-icons.md-light,
.material-icons-outlined.md-light {
  color: $bgGray400;
}
.material-icons.md-light.md-inactive {
  color: $bgGray400;
}

/* Icons - Colors */

.icon-default {
  color: $bgGray600 !important;
}
.icon-muted {
  color: $bgGray500 !important;
}
.icon-light {
  color: $bgGray400 !important;
}
.icon-success {
  color: $bgGreen500 !important;
}
.color-yellow-400 {
  color: $bgYellow400 !important;
}
.icon-warning,
.color-yellow-500 {
  color: $bgYellow500 !important;
}
.icon-danger {
  color: $bgRed500 !important;
}
.icon-info {
  color: $bgTeal400 !important;
}
.icon-primary {
  color: $bgBlue500 !important;
}
.icon-active,
.icon-blue600 {
  color: $bgBlue600 !important;
}
.icon-secondary,
.icon-aqua {
  // Deprecate .icon-aqua
  color: $bgTeal300 !important;
}
.icon-transparent {
  color: transparent !important;
}
.icon-bold {
  font-weight: 600 !important;
}

/* Icon - Sizes  (default 24px) */

.material-icons.md-16,
.material-icons-outlined.md-16 {
  // Usage Nav 'open_new_tab'
  font-size: 16px !important;
}
.material-icons.md-18,
.material-icons-outlined.md-18 {
  font-size: 18px;
}
.material-icons.md-20,
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.material-icons.md-22,
.material-icons-outlined.md-22 {
  font-size: 22px;
}
.material-icons.md-26,
.material-icons-outlined.md-26 {
  font-size: 26px;
}
.material-icons.md-28,
.material-icons-outlined.md-28 {
  font-size: 28px;
}
.material-icons.md-30,
.material-icons-outlined.md-30 {
  font-size: 30px;
}
.material-icons.md-32,
.material-icons-outlined.md-32 {
  font-size: 32px;
}
.material-icons.md-34,
.material-icons-outlined.md-34 {
  font-size: 34px;
}
.material-icons.md-36,
.material-icons-outlined.md-36 {
  font-size: 36px;
}
.material-icons.md-48,
.material-icons-outlined.md-48 {
  font-size: 48px;
}
.material-icons.md-60,
.material-icons-outlined.md-60 {
  font-size: 60px;
}
.material-icons.md-72,
.material-icons-outlined.md-72 {
  font-size: 72px;
}

/*---------------------
OPACITY
----------------------*/

.opacity-100 {
  opacity: 1;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-10 {
  opacity: 0.1;
}

/* Grid */

.g-0 {
  margin-right: 0;
  margin-left: 0;
}
.g-0 > .col,
.g-0 > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.grid-mb-1 {
  margin-bottom: 1.875rem;
}
.grid-mt-1 {
  margin-top: 1.875rem;
}

/*---------------------
SIZING
----------------------*/
// References
// https://designsystem.digital.gov/utilities/height-and-width/
// https://tailwindcss.com/docs/width

// Bootstrap Percentages
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}

// Height Fixed
.height-inherit {
  height: inherit !important;
}
.height-auto {
  height: auto !important;
}
.height-20 {
  height: 20px !important;
}
.height-22 {
  height: 22px !important;
}
.height-24 {
  height: 24px !important;
}
.height-26 {
  height: 26px !important;
}
.height-28 {
  height: 28px !important;
}
.height-30 {
  height: 30px !important;
}
.height-32 {
  height: 32px !important;
}
.height-34 {
  height: 34px !important;
}
.height-36 {
  height: 36px !important;
}

// Width Fixed
.width-20 {
  width: 20px !important;
}
.width-22 {
  width: 22px !important;
}
.width-24 {
  width: 24px !important;
}
.width-26 {
  width: 26px !important;
}
.width-28 {
  width: 28px !important;
}
.width-30 {
  width: 30px !important;
}
.width-32 {
  width: 32px !important;
}
.width-34 {
  width: 34px !important;
}
.width-36 {
  width: 36px !important;
}

// Square - Icons
.square-16 {
  width: 16px;
  height: 16px;
}
.square-18 {
  width: 18px;
  height: 18px;
}
.square-20 {
  width: 20px;
  height: 20px;
}
.square-22 {
  width: 22px;
  height: 22px;
}
.square-24 {
  width: 24px;
  height: 24px;
}
.square-26 {
  width: 26px;
  height: 26px;
}
.square-28 {
  width: 28px;
  height: 28px;
}
.square-30 {
  width: 30px;
  height: 30px;
}
.square-32 {
  width: 32px;
  height: 32px;
}
.square-34 {
  width: 34px;
  height: 34px;
}
.square-36 {
  width: 36px;
  height: 36px;
}
.square-40 {
  width: 40px;
  height: 40px;
}
.square-44 {
  width: 44px;
  height: 44px;
}
.square-48 {
  width: 48px;
  height: 48px;
}
.square-52 {
  width: 52px;
  height: 52px;
}
.square-56 {
  width: 56px;
  height: 56px;
}
.square-60 {
  width: 60px;
  height: 60px;
}

/* Element Max Width - Limit growing above... */

.max-w-10 {
  max-width: 10rem; //130px
}
.max-w-11 {
  max-width: 11rem; //143px
}
.max-w-12 {
  max-width: 12rem; //156px
}
.max-w-13 {
  max-width: 13rem; //169px
}
.max-w-14 {
  max-width: 14rem; //182px
}
.max-w-15 {
  max-width: 15rem; //195px
}
.max-w-16 {
  max-width: 16rem; //208px
}
.max-w-17 {
  max-width: 17rem; //221px
}
.max-w-18 {
  max-width: 18rem; //234px
}
.max-w-19 {
  max-width: 19rem; //247px
}
.max-w-20 {
  max-width: 20rem; //260px
}

/* Content Max Width - Limit growing above... */

.max-width-sm {
  max-width: 780px;
}
.max-width-md,
.sk-max-width-md {
  // Deprecate prefix 'sk'
  max-width: 1020px;
}
.max-width-lg,
.sk-max-width-lg {
  max-width: 1260px;
}
.max-width-xl,
.sk-max-width-xl {
  max-width: 1500px;
}
.max-width-xxl {
  max-width: 1740px;
}

/* Content Min Width - Force scrolling below... */

.min-width-sm,
.sk-min-width-sm {
  // Deprecate prefix 'sk'
  min-width: 540px;
}
.min-width-md,
.sk-min-width-md {
  min-width: 720px;
}
.min-width-lg,
.sk-min-width-lg {
  min-width: 960px;
}
.min-width-xl {
  min-width: 1140px;
}

/* Input Width - Search Input */

.input-width-sm {
  width: 18rem;
}
.input-width-md {
  width: 22rem;
}
.input-width-lg {
  width: 26rem;
}
.input-width-xl {
  width: 32rem;
}
.input-width-max {
  max-width: 40rem; // 40rem=520px
}

/* Input Height */

.input-height-min {
  height: 24px !important;
}

/* Spacing */

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

/* Minimum for flex content (default=auto) */

.min-w-0 {
  min-width: 0;
}
.max-w-0 {
  max-width: 0;
}
.min-h-0 {
  min-height: 0;
}
.min-w-reset {
  // override button min-width
  min-width: inherit !important;
}

/*---------------------
SPACING - MARGIN
----------------------*/

.m-0 {
  margin: 0 !important;
}

/* 25% */

.mt-25 {
  margin-top: 0.25rem !important;
}
.mr-25 {
  margin-right: 0.25rem !important;
}
.mb-25 {
  margin-bottom: 0.25rem !important;
}
.ml-25 {
  margin-left: 0.25rem !important;
}
.mx-25 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.my-25 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.255rem !important;
}
.m-25 {
  margin-top: 0.25rem !important;
  margin-right: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  margin-left: 0.25rem !important;
}

/* 50% */

.mt-50 {
  margin-top: 0.5rem !important;
}
.mr-50 {
  margin-right: 0.5rem !important;
}
.mb-50 {
  margin-bottom: 0.5rem !important;
}
.ml-50 {
  margin-left: 0.5rem !important;
}
.mx-50 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.my-50 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.m-50 {
  margin-top: 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  margin-left: 0.5rem !important;
}

/* 75% */

.mt-75 {
  margin-top: 0.75rem !important;
}
.mr-75 {
  margin-right: 0.75rem !important;
}
.mb-75 {
  margin-bottom: 0.75rem !important;
}
.ml-75 {
  margin-left: 0.75rem !important;
}
.mx-75 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.my-75 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.m-75 {
  margin-top: 0.75rem !important;
  margin-right: 0.75rem !important;
  margin-bottom: 0.75rem !important;
  margin-left: 0.75rem !important;
}

/* 100% */

.mt-100 {
  margin-top: 1rem !important;
}
.mr-100 {
  margin-right: 1rem !important;
}
.mb-100 {
  margin-bottom: 1rem !important;
}
.ml-100 {
  margin-left: 1rem !important;
}
.mx-100 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.my-100 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.m-100 {
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
}

/* 150% */

.mt-150 {
  margin-top: 1.5rem !important;
}
.mr-150 {
  margin-right: 1.5rem !important;
}
.mb-150 {
  margin-bottom: 1.5rem !important;
}
.ml-150 {
  margin-left: 1.5rem !important;
}
.mx-150 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.my-150 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.m-150 {
  margin-top: 1.5rem !important;
  margin-right: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  margin-left: 1.5rem !important;
}

/* 200% */

.mt-200 {
  margin-top: 2rem !important;
}
.mr-200 {
  margin-right: 2rem !important;
}
.mb-200 {
  margin-bottom: 2rem !important;
}
.ml-200 {
  margin-left: 2rem !important;
}
.mx-200 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.my-200 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.m-200 {
  margin-top: 2rem !important;
  margin-right: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 2rem !important;
}

/* 300% */

.mt-300 {
  margin-top: 3rem !important;
}
.mr-300 {
  margin-right: 3rem !important;
}
.mb-300 {
  margin-bottom: 3rem !important;
}
.ml-300 {
  margin-left: 3rem !important;
}
.mx-300 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.my-300 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.m-300 {
  margin-top: 3rem !important;
  margin-right: 3rem !important;
  margin-bottom: 3rem !important;
  margin-left: 3rem !important;
}

/* Negative Margin */

.mx-n50 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.mx-n100 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.mx-n200 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.ml-n25 {
  margin-left: -0.25rem !important;
}
.ml-n50 {
  margin-left: -0.5rem !important;
}
.ml-n100 {
  margin-left: -1rem !important;
}
.ml-n200 {
  margin-left: -2rem !important;
}
.ml-n300 {
  margin-left: -3rem !important;
}
.mr-n25 {
  margin-right: -0.25rem !important;
}
.mr-n50 {
  margin-right: -0.5rem !important;
}
.mr-n100 {
  margin-right: -1rem !important;
}
.mr-n200 {
  margin-right: -2rem !important;
}
.mr-n300 {
  margin-right: -3rem !important;
}

/*---------------------
SPACING - PADDING
----------------------*/

.p-0 {
  padding: 0 !important;
}

/* 25% */

.pt-25 {
  padding-top: 0.25rem !important;
}
.pr-25 {
  padding-right: 0.25rem !important;
}
.pb-25 {
  padding-bottom: 0.25rem !important;
}
.pl-25 {
  padding-left: 0.25rem !important;
}
.px-25 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.py-25 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.p-25 {
  padding-top: 0.25rem !important;
  padding-right: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 0.25rem !important;
}

/* 50% */

.pt-50 {
  padding-top: 0.5rem !important;
}
.pr-50 {
  padding-right: 0.5rem !important;
}
.pb-50 {
  padding-bottom: 0.5rem !important;
}
.pl-50 {
  padding-left: 0.5rem !important;
}
.px-50 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.py-50 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.p-50 {
  padding-top: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0.5rem !important;
}

/* 75% */

.pt-75 {
  padding-top: 0.75rem !important;
}
.pr-75 {
  padding-right: 0.75rem !important;
}
.pb-75 {
  padding-bottom: 0.75rem !important;
}
.pl-75 {
  padding-left: 0.75rem !important;
}
.px-75 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.py-75 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.p-75 {
  padding-top: 0.75rem !important;
  padding-right: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  padding-left: 0.75rem !important;
}

/* 100% */

.pt-100 {
  padding-top: 1rem !important;
}
.pr-100 {
  padding-right: 1rem !important;
}
.pb-100 {
  padding-bottom: 1rem !important;
}
.pl-100 {
  padding-left: 1rem !important;
}
.px-100 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.py-100 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.p-100 {
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important;
}

/* 150% */

.pt-150 {
  padding-top: 1.5rem !important;
}
.pr-150 {
  padding-right: 1.5rem !important;
}
.pb-150 {
  padding-bottom: 1.5rem !important;
}
.pl-150 {
  padding-left: 1.5rem !important;
}
.px-150 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.py-150 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.p-150 {
  padding-top: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  padding-left: 1.5rem !important;
}

/* 200% */

.pt-200 {
  padding-top: 2rem !important;
}
.pr-200 {
  padding-right: 2rem !important;
}
.pb-200 {
  padding-bottom: 2rem !important;
}
.pl-200 {
  padding-left: 2rem !important;
}
.px-200 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.py-200 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.p-200 {
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 2rem !important;
}

/* 300% */

.pt-300 {
  padding-top: 3rem !important;
}
.pr-300 {
  padding-right: 3rem !important;
}
.pb-300 {
  padding-bottom: 3rem !important;
}
.pl-300 {
  padding-left: 3rem !important;
}
.px-300 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.py-300 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.p-300 {
  padding-top: 3rem !important;
  padding-right: 3rem !important;
  padding-bottom: 3rem !important;
  padding-left: 3rem !important;
}

/*---------------------
TEXT - TYPOGRAPHY
----------------------*/

/* Alignment -- (Bootstrap v4 include) */

.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}

/* Text - Color */

.text-success {
  color: $textSuccess !important;
}
.text-warning {
  color: $textWarning !important;
}
.text-danger {
  color: $textDanger !important;
}
.text-white {
  color: #fff !important;
}
.text-dark {
  color: $textDark !important;
}
.text-meta {
  color: $textMeta !important;
}
.text-link {
  color: $textLink !important;
}
.text-muted {
  color: $textMuted !important;
}

/* Text - Size */

.text-xs {
  font-size: 11px !important;
}
.text-sm {
  font-size: 12px !important;
}
.text-md {
  font-size: 13px !important;
}
.text-lg {
  font-size: 14px !important;
}
.text-xl {
  font-size: 15px !important;
}

/* Text - Weight */

.font-300,
.font-light,
font-weight-light {
  font-weight: 300 !important;
}
.font-400,
.font-weight-normal {
  font-weight: 400 !important;
}
.font-500,
.font-medium,
.font-weight-medium {
  font-weight: 500 !important;
}
.font-700,
.font-bold,
.font-weight-bold {
  font-weight: 700 !important;
}

/* Text - Line Height (Leading)*/

// Relative Line Height

.line-height-100,
.line-height-none {
  line-height: 1rem !important;
}
.line-height-150 {
  line-height: 1.5rem !important; // 19.5px
}
.line-height-200 {
  line-height: 2rem !important; // 26px
}
.line-height-250 {
  line-height: 2.5rem !important; // 32.5px
}
.line-height-300 {
  line-height: 3rem !important; // 39px
}

// Fixed Line Height

.line-height-16 {
  line-height: 16px !important;
}
.line-height-18 {
  line-height: 18px !important;
}
.line-height-20,
.line-height-normal {
  line-height: 20px !important;
}
.line-height-22 {
  line-height: 22px !important;
}
.line-height-24 {
  line-height: 24px !important;
}
.line-height-26 {
  line-height: 26px !important;
}
.line-height-28 {
  line-height: 28px !important;
}
.line-height-30 {
  line-height: 30px !important;
}
.line-height-34 {
  // Button/Input height
  line-height: 34px !important;
}
.line-height-36 {
  line-height: 36px !important;
}
.line-height-38 {
  line-height: 38px !important;
}
.line-height-40 {
  line-height: 40px !important;
}
.line-height-42 {
  line-height: 42px !important;
}
.line-height-44 {
  line-height: 44px !important;
}
.line-height-46 {
  line-height: 46px !important;
}
.line-height-48 {
  // Table row height
  line-height: 48px !important;
}

/* Text (Bootstrap v4 Utility) */

.text-start {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-end {
  text-align: right !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-dec-none,
.text-dec-none:hover,
.text-dec-none a {
  text-decoration: none !important;
}
/* For use with Tooltips & Popovers */
.text-underline-dotted,
.text-underline-dotted:hover {
  border-bottom: 1px dotted !important;
  text-decoration: none !important;
}

/*---------------------
INTERACTIONS
----------------------*/

.link-hover:hover,
.hover-blue:hover {
  // DEPRECATE .hover-blue
  color: $textLinkHover !important;
  text-decoration: underline;
  cursor: pointer;
}
.row-hover:hover {
  background-color: $bgBlue50 !important;
}
.row-hover:hover a {
  color: $textLink !important;
  cursor: pointer;
}
.row-hover:hover a:hover {
  color: $textLinkHover !important;
  text-decoration: underline;
  cursor: pointer;
}

/* CURSOR */

.cursor-pointer {
  // Hand
  cursor: pointer !important;
}
.cursor-default {
  // Arrow
  cursor: default !important;
}

/*---------------------
DISPLAY -- (Bootstrap v4 include)
----------------------*/

.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

/* Responsive breakpoints */
@media (max-width: 575px) {
  .d-xs-none {
    /* Fix for override d-none */
    display: none !important;
  }
}
@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
}

/*---------------------
FLEX -- (Bootstrap v4 include)
----------------------*/

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
// Custom Flex Utilities
.flex-basis-0 {
  flex-basis: 0;
}
.flex-center-center {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

/*  REBOOT 4.5 */

// Cursor as a pointer
[role='button'] {
  cursor: pointer;
}

/*  BOOTSTRAP 5 GRID XXL */

@media (min-width: 1400px) {
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

/*  BOOTSTRAP 5 GRID XXXL */

@media (min-width: 1600px) {
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

/* DEVELOPMENT & TESTING */

body.show-grid {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    box-shadow: 0px 0px 0px 1px lightpink;
  }
}
