/*!
 * SkyKick CSS components v3.0.0
 * Compiled _skykick.css (Bootstrap v5.2)
 * Copyright 2024 SkyKick, Inc.
*/

/*---------------------
IMPORT SASS
----------------------*/
// Variables first
@import './components/variables';
// All components
@import './components/accordion';
@import './components/alert';
@import './components/avatar';
@import './components/badges';
@import './components/buttons';
@import './components/cards';
@import './components/forms';
@import './components/modal';
@import './components/navs';
@import './components/panels';
@import './components/popover';
@import './components/progress';
@import './components/tables';
@import './components/toast';
@import './components/typography';
// Utilities loading at the bottom


/*---------------------
DRAFT COMPONENTS
----------------------*/
// Data Visualizations
.sk-data-delta {
  display: inline;
}
.sk-data-delta.sk-data-1 {
  font-size: 13px;
}
.sk-data-delta.sk-data-2 {
  font-size: 16px;
}
.sk-data-delta.sk-data-3 {
  font-size: 20px;
}
.sk-data-delta .material-icons {
  font-weight: 600;
  vertical-align: middle;
  line-height: inherit;
  position: relative;
  top: -1px;
}
.sk-data-delta .sk-delta-value {
  vertical-align: top;
  font-weight: 500;
}

/*---------------------
HUBSPOT CTA
----------------------*/
.hs-cta-link {
  color: #056ed1;
}
.hs-cta-link:hover {
  text-decoration: underline;
}

/*---------------------
IMPORT SASS
----------------------*/

@import './components/utilities';

/*---------------------
THE END
----------------------*/
