@import "./variables";

.toast-success {
  background-color: $bgGreen600;
  background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0.663574 8.99545C0.663574 4.39629 4.39623 0.663637 8.99539 0.663637C13.5946 0.663637 17.3272 4.39629 17.3272 8.99545C17.3272 13.5946 13.5946 17.3273 8.99539 17.3273C4.39623 17.3273 0.663574 13.5946 0.663574 8.99545ZM3.31762 9.14995L7.48353 13.3159L14.9822 5.81723L13.8074 4.63411L7.48353 10.958L4.49241 7.97517L3.31762 9.14995Z" fill="white"/> </svg>') !important;
}
.toast-error {
  background-color: $bgRed600;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0.831787 15.918H19.1682L9.99997 0.082016L0.831787 15.918ZM10.8334 13.4175H9.1665V11.7506H10.8334V13.4175ZM10.8334 10.0837H9.1665V6.7498H10.8334V10.0837Z" fill="white"/> </svg>') !important;
}
.toast-warning {
  background-color: $bgYellow600;
  background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00003 0.668228C4.40087 0.668228 0.668213 4.40088 0.668213 9.00005C0.668213 13.5992 4.40087 17.3319 9.00003 17.3319C13.5992 17.3319 17.3318 13.5992 17.3318 9.00005C17.3318 4.40088 13.5992 0.668228 9.00003 0.668228ZM9.83324 13.166H8.16688V11.4996H9.83324V13.166ZM9.83324 9.83319H8.16688V4.8341H9.83324V9.83319Z" fill="white"/> </svg>') !important;
}
.toast-info {
  background-color: $bgTeal500;
  background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00003 0.668224C4.40087 0.668224 0.668213 4.40088 0.668213 9.00004C0.668213 13.5992 4.40087 17.3319 9.00003 17.3319C13.5992 17.3319 17.3318 13.5992 17.3318 9.00004C17.3318 4.40088 13.5992 0.668224 9.00003 0.668224ZM9.83324 13.166H8.16688V8.16689H9.83324V13.166ZM9.83324 6.50046H8.16688V4.83409H9.83324V6.50046Z" fill="white"/> </svg>') !important;
}

.toast-container {
  position: fixed;
  
  .ngx-toastr,
  .ngx-toastr:hover {
    box-shadow: none !important;
  }
}
