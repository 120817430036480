@import './variables';

/*---------------------
BUTTONS
----------------------*/

/* Links */
a {
  color: $textLink;
  text-decoration: none;
  cursor: pointer;
}
a:focus,
a:hover {
  color: $textLinkHover;
  text-decoration: underline;
}
a:hover.btn{
  text-decoration: none;
}
a.sk-hover-hide {
  text-decoration: none;
}

/* Default States */
button,
button:focus {
  outline: none;
  box-shadow: none;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.show:focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
:focus {
  box-shadow: none;
  outline: none;
}
.btn.disabled,
.btn:disabled,
fieldset[disabled] .btn {
  opacity: 0.55;
}
.navbar-toggler:focus {
  outline: none;
}
.navbar-toggler:hover {
  color: #333;
  outline: none;
  background-color: #f6f8fc;
}
.btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  padding: 0 1rem;
}
.btn-close {
  border: 0;
}
.btn-lg {
  font-size: 16px;
  height: 44px;
  line-height: 44px;
  padding: 0 1.5rem;
  border-radius: $border-radius-sm;
}
.btn-sm,
.btn-group-sm > .btn {
  font-size: 12px;
  line-height: 30px;
  padding: 0 1rem;
  height: 30px;
}
// Minimum Widths
.btn:not(.sk-btn-icon):not(.btn.dropdown-toggle-split):not(
    .sk-btn-icon-basic
  ):not(.sk-btn-combo):not(.input-group .btn):not(.ngb-dp-arrow-btn):not(.ngb-tp-meridian .btn):not(
    .ngb-tp-input-container .btn
  ) {
  min-width: 70px;
}
.btn.btn-lg:not(.sk-btn-icon):not(.btn.dropdown-toggle-split):not(
    .sk-btn-icon-basic
  ):not(.sk-btn-combo):not(.input-group .btn):not(.ngb-dp-arrow-btn):not(.ngb-tp-meridian .btn):not(
    .ngb-tp-input-container .btn
  ) {
  min-width: 86px;
}
.input-group .input-group-append .btn {
  min-width: inherit !important;
}

/* ICON-ONLY BUTTON */
.sk-btn-icon, .sk-btn-icon:focus  {
  align-items: center;
  display: inline-flex;
  padding: 0 7px;
  text-decoration: none !important;
  border-color: transparent;
}
.sk-btn-icon {
  &:hover {
    text-decoration: none !important;
    border-color: transparent;
  }
  &.btn:active,&.btn.active {
    text-decoration: none !important;
    border-color: transparent;
  }
}
.sk-btn-icon.show,
.sk-btn-icon.show:focus,
.sk-btn-icon.show:active {
  border-color: transparent;
}
.btn > i.material-icons,
.btn i.material-icons {
  display: flex;
  align-items: center;
}
.sk-btn-icon:hover .material-icons {
  color: $bgGray800;
}
.sk-btn-icon.show .material-icons {
  color: $bgGray900;
}

/* PRIMARY BUTTON */
.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: $btnPrimary;
  border-color: $btnPrimary;
}
.btn-primary:hover {
  color: #fff;
  background-color: $btnPrimaryHover;
  border-color: $btnPrimaryHover;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: $btnPrimaryActive;
  border-color: $btnPrimaryActive;
}
/* show dropdown */
.btn-primary.show,
.btn-primary.show:active,
.btn-primary.show:focus {
  color: #fff;
  background-color: $btnPrimaryActive;
  border-color: $btnPrimaryActive;
}
.btn-primary:disabled,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus {
  color: #fff;
  background-color: $btnPrimary;
  border-color: $btnPrimary;
  opacity: 0.45;
}
.btn-primary:disabled .material-icons,
.btn-primary:disabled:hover .material-icons,
.btn-primary:disabled:focus .material-icons {
  color: #fff;
}

/* TERTIARY BUTTON - Outline Primary  */
.btn-outline-primary, .btn-outline-primary:focus {
  color: $btnTextBlue;
  background-color: #fff;
  border-color: $borderBlue400;
  box-shadow: none;
}
.btn-outline-primary:hover {
  color: $btnTextBlueHover;
  background-color: $bgBlue50;
  border-color: $borderBlue500;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: $btnTextBlueHover;
  background-color: $bgBlue100;
  border-color: $borderBlue500;
}
/* show dropdown */
.btn-outline-primary.show,
.btn-outline-primary.show:active,
.btn-outline-primary.show:focus {
  color: $btnTextBlue;
  background-color: $bgBlue100;
  border-color: $borderBlue500;
  box-shadow: none;
}
.btn-outline-primary:disabled,
.btn-outline-primary:disabled:hover,
.btn-outline-primary:disabled:focus {
  color: $btnTextDisabled;
  background-color: $btnBgDisabled;
  border-color: $btnBorderDisabled;
}

/* SECONDARY BUTTON - Outline Secondary */
.btn-outline-secondary, .btn-outline-secondary:focus{
  color: $btnTextDark500;
  background-color: #fff;
  border-color: $borderGray700;
}
.btn-outline-secondary:hover{
  color: $btnTextDark500;
  background-color: $bgGray100;
  border-color: $borderGray900;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: $btnTextDark600;
  background-color: $bgGray300;
  border-color: $borderGray900;
}
/* show dropdown */
.btn-outline-secondary.show,
.btn-outline-secondary.show:active,
.btn-outline-secondary.show:focus {
  color: $btnTextDark600;
  background-color: $bgGray300;
  border-color: $borderGray900 !important;
}
.btn-outline-secondary:disabled,
.btn-outline-secondary:disabled:hover{
  color: $btnTextDisabled;
  background-color: $btnBgDisabled;
  border-color: $btnBorderDisabled;
}

/* BASIC BUTTON */
.btn-basic, .btn-basic:focus  {
  color: $btnTextDark500;
  background-color: #fff;
  border-color: #fff;
  text-decoration: none;
}
.btn-basic:hover {
  color: $btnTextDark500;
  background-color: $bgGray100;
  border-color: $bgGray100;
}
.btn-basic.active,
.btn-basic:active,
.btn-basic:first-child:active {
  color: $btnTextDark500;
  background-color: $bgGray200;
  border-color: #fff;
}
/* show dropdown */
.btn-basic.show,
.btn-basic.show:active,
.btn-basic.show:focus {
  color: $btnTextDark500;
  background-color: $bgGray200;
  border-color: $bgGray200;
}
.btn-basic:disabled,
.btn-basic:disabled:hover {
  color: $btnTextDisabled;
  background-color: $bgWhite;
  border-color: $bgWhite;
}

/* LINK BUTTON */
.btn-link, .btn-link:focus {
  color: $btnTextBlue;
  border-radius: $border-radius-sm;
  text-decoration: none;
}
.btn-link:hover {
  color: $btnTextBlueHover;
  text-decoration: underline;
}
.btn-link.active,
.btn-link:active {
  color: $btnTextBlueHover;
  text-decoration: underline;
}
/* show dropdown */
.btn-link.show,
.btn-link.show:active,
.btn-link.show:focus {
  color: $btnTextBlueHover;
}
.btn-link:disabled,
.btn-link:disabled:hover {
  color: $btnTextDisabled;
}

/* DANGER BUTTON */
.btn-danger, .btn-danger:focus {
  background-color: $bgRed500;
  border-color: $bgRed500;
}
.btn-danger:hover {
  background-color: $bgRed500-hover;
  border-color: $bgRed500-hover;
}
/* active */
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: $bgRed500-active;
  border-color: $bgRed500-active;
}
/* disabled */
.btn-danger:disabled,
.btn-danger:disabled:hover {
  color: $btnTextDisabled;
  background-color: $btnBgDisabled;
  border-color: $btnBorderDisabled;
}

/*---------------------
BUTTON GROUP
----------------------*/
.btn-group > .btn,
.btn-group-vertical > .btn {
  margin-right: 0;
}
.btn-group > .btn-secondary {
  margin-right: 2px;
}
.btn-group > .btn-primary {
  margin-right: 2px;
}
.btn-group.open .sk-btn-icon-basic.dropdown-toggle,
.btn-group.open .btn-basic.dropdown-toggle,
.btn-group.open .btn-link.dropdown-toggle {
  background: #fff;
  border: 1px solid #5caffa;
  box-shadow: 0 0 0 1px #95ceff;
}
.btn-group label {
  margin-bottom: 0;
}

/*---------------------
BUTTON GROUP RADIO TOGGLE
----------------------*/
.btn-check:checked + .btn.btn-outline-secondary{
  color: $btnTextDark500;
  background-color: $bgBlue200;
  box-shadow: none;
  border-color: $borderGray800;
}
.btn-check + .btn.btn-outline-secondary:hover{
  color: $btnTextDark500;
  background-color: $bgBlue200;
  box-shadow: none;
  border-color: $borderGray800;
}

/*---------------------
DROPDOWN
----------------------*/
.dropdown-toggle::after {
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid $borderGray700;
  box-shadow: 0px 1px 3px rgba(91, 102, 111, 0.3);
}
.dropdown-item {
  font-weight: normal;
  line-height: 3rem;
  padding: 0 1rem;
  color: $textBody;
}
.dropdown-item.disabled {
  color: $textDisabled;
}
.dropdown-divider {
  margin: 0;
  border-color: $borderGray500;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: $textBody;
  text-decoration: none;
  background-color: $bgBlue200;
}
.dropdown-item.active,
.dropdown-item.active:focus,
.dropdown-item.active:hover {
  color: $textBody;
  font-weight: 500;
  background-color: $bgBlue100;
}
.dropdown-header {
  background-color: $bgGray50;
  border-top: 1px solid #dbe1e6;
  border-bottom: 1px solid #dbe1e6;
  color: $textDark;
  font-weight: 500;
  font-size: 12px;
  line-height: 3rem;
  padding: 0 1rem;
}
.dropdown-menu .sk-header-flush {
  border-top: none;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
// Dropdown Menu with Checkboxes
.dropdown-item .form-check {
  margin-bottom: 0px;
}
.dropdown-item .form-check-input {
  margin-top: 10px;
}
.dropdown-item .form-check-input-sm {
  margin-top: 11px;
}


/* TABLE MENU */

.dropdown-menu .table {
  margin: 0;
}
.dropdown-menu .table td {
  line-height: 3rem;
  padding: 0 1rem;
  border: none;
}
.dropdown-menu .table tr:first-child td,
.dropdown-menu .table tr:last-child td {
  border: none;
}
.dropdown-menu .table td {
  line-height: 3rem;
  padding: 0;
}
.dropdown-menu .table-hover tbody tr:hover {
  background-color: rgba(168, 215, 255, 0.5);
}

/* table dropdown items */

.dropdown-menu .sk-dropdown-table td:first-child {
  padding-left: 0;
}
.dropdown-menu .table td .dropdown-item {
  padding: 0 1rem 0 0;
}
.dropdown-menu .table td:first-child .dropdown-item {
  padding: 0 1rem;
}
.dropdown-menu .table tr:hover .dropdown-item {
  color: #2b2d2f;
}
.dropdown-menu .table a.dropdown-item:hover {
  background-color: transparent;
}
.dropdown-menu .table .dropdown-item:focus {
  background-color: transparent;
  color: #2b2d2f;
}
.dropdown-menu .table tr:hover .dropdown-item.active,
.dropdown-menu .table tr:hover .dropdown-item.active:focus,
.dropdown-menu .table tr:hover .dropdown-item.active:hover {
  color: #2b2d2f;
  font-weight: 500;
  background-color: rgb(225, 241, 255);
}
.dropdown-menu .table .dropdown-item.active:focus {
  background-color: rgba(196, 227, 255, 0.5);
}
.sk-menu-scroll {
  height: auto;
  max-height: 320px;
  overflow-x: hidden;
}
.sk-menu-input {
  min-width: 208px;
}
.sk-menu-input .sk-input {
  margin: 6px 1rem;
  max-width: 180px;
}

/* Legacy Bootstrap Block Button */
.btn-block {
  // display: block; // Removing legacy block display
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: .5rem; // from legacy variable $btn-block-spacing-y
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

/* Button Block */
.btn-block:not(.dropdown-toggle) {
  justify-content: center;
}
.btn-block:not(.dropdown-toggle) {
  justify-content: center;
}
.btn-block .dropdown-toggle.btn-block::after {
  position: absolute;
  right: 1rem;
}
.btn-block .dropdown-menu.btn-block {
  margin-top: 2px;
}
.btn-group i.flag {
  margin-right: 8px;
}

/*---------------------
ICONS
----------------------*/
.sk-btn-combo {
  display: inline-flex;
  align-items: center;
}
.sk-btn-combo.btn-link,
.sk-btn-combo.btn-basic {
  &:hover {
    text-decoration: none !important;
  }
  &.active {
    text-decoration: none !important;
  }
  &:focus {
    text-decoration: none !important;
  }
}
.btn:disabled:not(.btn-primary) .material-icons {
  color: $bgGray600;
}
.caret-hidden:after,
.sk-btn-icon-more:after,
.sk-btn-icon-select:after {
  /* DEPRECATE .sk-btn-icon-more, .sk-btn-icon-select  */
  display: none;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}
//  Dropdown Items
.dropdown-item {
  display: flex;
}
.dropdown-item.disabled i.material-icons {
  color: $bgGray400;
}
.dropdown-item > i.material-icons {
  display: flex;
  align-items: center;
  padding-right: 6px;
}


/* Icon Button Toggle - Panels */
.sk-btn-icon.btn-toggle{
  &.active,
  &:active {
    background: $bgBlue200;
  }
  &.stashed {
    background: $bgGray200;
  }
  &:hover:not(.active) {
    background: $bgGray200;
  }
}
.sk-btn-icon.btn-toggle.active:not([disabled]) .material-icons,
.sk-btn-icon.btn-toggle:active:not([disabled]) .material-icons {
  color: $bgBlue600;
}
.sk-btn-icon.btn-toggle:focus .material-icons,
.sk-btn-icon.btn-toggle:focus:active .material-icons {
  color: $bgGray800;
}
.sk-btn-icon.btn-toggle.active:hover .material-icons,
.sk-btn-icon.btn-toggle.active:focus .material-icons {
  color: $bgBlue600;
}

/* Search Input Button */

.sk-btn-search {
  padding: 0 10px;
}
.sk-btn-search-outline {
  padding: 0 10px;
}
.sk-btn-search-outline:disabled .material-icons,
.sk-btn-search-outline:disabled:hover .material-icons {
  color: $bgGray500 !important;
}
.sk-btn-search-outline:hover .material-icons,
.sk-btn-search-outline:focus .material-icons {
  color: $bgGray700;
}
.btn-outline-secondary.sk-btn-search-outline:disabled,
.btn-outline-secondary.sk-btn-search-outline:disabled:hover,
.btn-outline.sk-btn-search-outline:disabled,
.btn-outline.sk-btn-search-outline:disabled:hover {
  background-color: #fff;
  border-color: $borderGray700;
  opacity: 1;
}

.btn.sk-btn-flush,
.btn.sk-back-flush {
  // DEPRECATE (Use utilities "ps-0 ml-n50")
  padding-left: 0;
  margin-left: -6px;
}
.btn-primary .material-icons,
.btn-success .material-icons,
.btn-danger .material-icons {
  color: inherit;
}

/* icon dropdowns */

.dropdown-toggle i.md-22 {
  /* production fix */
  top: -2px;
  vertical-align: middle;
}
.sk-btn-combo i.md-22 {
  padding-right: 5px;
}

/* inline icons, inline button*/

.sk-btn-inline-icon {
  // DEPRECATE (Use flex utilities)
  line-height: 20px;
  height: 20px;
}
.sk-btn-inline-icon .material-icons {
  line-height: 1;
  vertical-align: baseline;
}
.sk-inline-icon .material-icons {
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 6px;
}

/* Button Collapse */

.btn-collapse {
  padding-left: 0;
  margin-left: -5px;
}
.btn-collapse i:before {
  display: inline-block;
  text-decoration: none !important;
  font-family: 'Material Icons';
  font-size: 22px;
  font-weight: 500;
  color: $bgGray600;
  content: '\e316'; //keyboard_arrow_down
}
.btn-collapse:focus {
  text-decoration: none;
}
.btn-collapse.collapsed i:before {
  content: '\e313'; //keyboard_arrow_up
}

/* Notifications badge overlay */

.notifications-bell {
  position: relative;
}
.notifications-bell .badge {
  position: absolute;
  top: 1px;
  left: 19px;
  pointer-events: none;
  box-shadow: 0 0 0 1px #fff;
}
