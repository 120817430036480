@import './variables';

/*---------------------
FORMS - INPUTS
----------------------*/

legend {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 3px;
}
.form-control {
  padding: 0.5rem 1rem;
  border: 1px solid $borderGray700;
  height: 34px;
  color: $textFormInput;
}
.form-control-lg {
  border-radius: 0.25rem;
  height: 44px;
}
.form-control:hover {
  border-color: $borderBlue500;
  background-color: #fff;
}
.form-control:focus {
  border-color: $borderBlue500;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px $borderBlue500;
  color: #23282d;
}
.form-control::-webkit-input-placeholder {
  color: #949ca2;
}
.form-control:-moz-placeholder {
  color: #949ca2;
}
.form-control::-moz-placeholder {
  color: #949ca2;
}
.form-control:-ms-input-placeholder {
  color: #949ca2;
}
.form-control[readonly] {
  background-color: #fff;
}
// Custom File Input
.form-control[type=file] {
  padding: 0 .75rem;
  height: auto;
  line-height: 32px;
}
.form-control.form-light {
  background-color: #e2e7ee;
  border-color: transparent;
}
.form-control.form-light:active,
.form-control.form-light:hover,
.form-control.form-light:focus {
  background-color: #dce2ea;
}
.form-control.form-light::-webkit-input-placeholder {
  color: #888;
}
.form-control.form-light:-moz-placeholder {
  color: #888;
}
.form-control.form-light::-moz-placeholder {
  color: #888;
}
.form-control.form-light:-ms-input-placeholder {
  color: #888;
}
.form-group { // .form-group=legacy
  margin-bottom: 1rem;
}

/* Add disabled and remove hover styles */
.form-control:disabled {
  background-color: #edf0f3;
  border-color: #d3d9dc;
  opacity: 0.6;
}
.form-label, .form-group label { // .form-group=legacy
  font-weight: 500;
  margin-bottom: 3px;
}
label.required:after,
.form-label.required:after {
  color: $bgRed600;
  content: ' *';
  font-weight: 700;
  padding-right: 1rem;
}
.form-text, .sk-form-text {
  font-size: 12px;
  margin-top: 3px;
  color: $textMuted;
  width: 100%;
}

/* Select Country */
.sk-select-flag .flag {
  position: absolute;
  margin: 11px 0 0 13px;
}
.sk-select-flag .form-select {
  padding-left: 36px;
}

/* Input Group hover/validation */
.input-group > .form-control {
  z-index: 3;
}
.input-group-append .btn,
.input-group-prepend .btn {
  &:hover {
    z-index: 4;
  }
  &:active {
    z-index: 4;
  }
  &:focus {
    z-index: 4;
  }
}

/* Inputs with icons */
.sk-input-icon,
.sk-input-group {
  position: relative;
}
.sk-input-icon .form-control {
  padding-right: 3rem;
}
.sk-input-icon i.md-18 {
  position: absolute;
  line-height: 1;
  text-align: center;
  cursor: default;
  top: 8px;
  right: 10px;
  opacity: 0.8;
}
.sk-input-icon i.md-24 {
  position: absolute;
  line-height: 1;
  text-align: center;
  cursor: default;
  top: 10px;
  right: 10px;
  pointer-events: none;
  opacity: 0.7;
}

/* Inputs with spinner */
.sk-input-group .sk-spin-loader.loader-sm {
  position: absolute;
  top: 6px;
  right: 6px;
  line-height: 34px;
  width: 22px;
  height: 22px;
}

/* Input Reset */
.input-group.sk-input-btn .form-control {
  padding-right: 34px;
}
.input-group.sk-input-btn .sk-reset-search {
  line-height: 1;
  padding: 8px;
  position: absolute;
  right: 39px;
  opacity: 0.8;
}
.sk-input-btn i.sk-reset-search {
  z-index: 3;
  cursor: pointer;
}
.sk-input-btn i.sk-reset-search:hover {
  color: rgba(74, 82, 93, 0.9);
}

/* Input Reset Lg */
.input-group-lg.sk-input-btn .sk-reset-search {
  padding: 11px 8px;
  right: 58px;
}
.input-group-btn .btn-lg .md-24,
.input-group-append .btn-lg .md-24 {
  top: 0;
}
.input-group-lg > .input-group-append > .btn.sk-btn-search {
  padding: 0.75rem;
}
.input-group-lg.sk-input-btn.sk-search-filter .sk-reset-search {
  right: 4px;
}
.input-group-lg.sk-input-btn.sk-search-filter .form-control {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* Input Icon Left */
.sk-input-icon.sk-icon-left .form-control {
  padding-left: 32px;
}
.input-group-lg.sk-icon-left .form-control {
  padding-left: 38px;
}
.sk-input-icon.sk-icon-left i.md-18,
.sk-input-icon.sk-icon-left i.md-24 {
  left: 8px;
  text-align: left;
  right: auto;
}
.form-group.sk-input-icon i.md-24 {
  top: 28px;
  opacity: 0.8;
}
.form-group.sk-input-icon .sk-input-group i.md-24 {
  top: 4px;
}
.input-group-lg > .form-control:not(textarea) {
  border-radius: 0.25rem;
  height: 44px;
}
.input-group-btn {
  line-height: 34px;
}
.sk-input-group-flip .btn {
  border-radius: 0;
}
.sk-input-group-flip .dropdown-toggle:after {
  margin-left: 5px;
}
.sk-input-group-flip:last-child .btn:not(.dropdown-toggle) {
  margin-left: -1px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* Password toggle */
.sk-input-icon .sk-password-toggle .material-icons {
  cursor: pointer;
  position: relative;
  top: 0;
  right: 0;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

// Validation
.invalid-feedback, .valid-feedback, .warning-feedback {
  display: block;
  font-size: 12px;
  margin-top: 3px;
  line-height: 20px;
  padding-left: 26px;
}
.invalid-feedback:before, .valid-feedback:before, .warning-feedback:before  {
  font-family: 'Material Icons';
  font-size: 20px;
  float: left;
  margin-left: -26px;
  line-height: 20px;
}

/* Validation - Invalid */
.form-control.is-invalid, .was-validated .form-control:invalid,
.form-select.form-control.is-invalid, .was-validated .form-select:invalid{
  border-color: $inputDanger;
  box-shadow: inset 0 0 0 1px $inputDanger;
  background-image: none !important;
}
.invalid-feedback, .invalid-feedback a {
  color: $textDanger;
}
.invalid-feedback:before {
  color: $bgRed500;
  content: '\e002'; // Material Icon: warning (triangle)
}
input.form-control.is-invalid:hover {
  border-color: $inputDanger;
  background-color: #fff;
}
input.form-control.is-invalid:focus,
select.form-control.is-invalid:focus {
  border-color: $inputDangerFocus;
  box-shadow: inset 0 0 0 1px $inputDangerFocus;
}
select.form-select.form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
}

/* Validation - Success */
.form-control.is-valid, .was-validated .form-control:valid {
  border-color: $inputSuccess;
  box-shadow: inset 0 0 0 1px $inputSuccess;
  background-image: none !important;
}
.valid-feedback {
  color: $textSuccess;
}
.valid-feedback:before {
  color: $bgGreen500;
  content: '\e86c'; // Material Icon: check (circle)
}
input.form-control.is-valid:focus {
  border-color: $inputSuccessFocus;
  box-shadow: inset 0 0 0 1px $inputSuccessFocus;
}

/* Validation - Warning */
.form-control.is-warning  {
  border-color: $inputWarning;
  box-shadow: inset 0 0 0 1px $inputWarning;
}
.warning-feedback {
  color: $textWarning;
}
.warning-feedback:before {
  color: $bgYellow500;
  content: '\e000'; // Material Icon: error (circle)
}
.has-warning:not(.input-group) .btn {
  border-color: $inputWarning;
  box-shadow: inset 0 0 0 1px $inputWarning;
}
input.form-control.is-warning:focus {
  border-color: $inputWarningFocus;
  box-shadow: inset 0 0 0 1px $inputWarningFocus;
}


/*---------------------
CHECKBOXES RADIOS
----------------------*/

.form-check-input {
  box-shadow: none;
  border: $form-check-input-border;
  margin-top: 1px;
  cursor: pointer;
}
.form-check-input-sm {
  width: $form-check-input-width-sm;
  height: $form-check-input-width-sm;
  margin-top: 2px;
}
.form-check-input:focus{
  border: $form-check-input-border;
  background-color: $form-check-input-bg-color;
  box-shadow: none;
}
.form-check-input:checked, .form-check-input:checked:disabled, .form-check-input[type=checkbox]:indeterminate{
  background-color: $form-check-input-checked-bg-color;
  border-color: $form-check-input-checked-border-color;
  box-shadow: none;
}
.form-check-input:disabled{
  background-color: $form-check-input-disabled-bg-color;
}
.form-check-input[type=checkbox]:checked {
  background-size: 90%;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K');
}
.form-check-input[type=checkbox]:indeterminate {
  background-size: 120%;
}
.form-check label {
  cursor: pointer;
}
.form-check label, .form-check .sk-form-text {
  padding-left: 3px;
}
// Tables and Flex/Grid Tables
table td .form-check, .list-item .form-check{
  margin-bottom: 0px;
}

// Validation
.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: none;
}
.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: $form-feedback-invalid-color;
  border-color: $form-feedback-invalid-color;
}
.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
  color: $form-feedback-invalid-text-color;
}
.form-check-input.is-invalid~.form-check-label a, .was-validated .form-check-input:invalid~.form-check-label a{
  color: $form-feedback-invalid-text-color;
  text-decoration: underline;
}

/*---------------------
CUSTOM FORM ELEMENTS
----------------------*/
// ng-bootstrap timepicker, datepicker, and AM-PM selector validation
.ng-invalid {
  ngb-timepicker {
    .ngb-tp-input,
    .ngb-tp-meridian button {
      border-color: $inputDanger;
      box-shadow: inset 0 0 0 1px $inputDanger;
    }
  }

  input[ngbdatepicker],
  button.calendar {
    border-color: $inputDanger;
    box-shadow: inset 0 0 0 1px $inputDanger;
  }
}

// ng-bootstrap timepicker spinner
.has-danger .ngb-tp-input-container .btn {
  border-color: transparent !important;
  box-shadow: none !important;
}

/* Datepicker - ngBootstrap Override */
button.calendar,
button.calendar:active,
button.calendar:active {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z" fill="%236e757d"/></svg>');
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: center;
  width: 44px;
}

/* Transparent Inputs */
.sk-input-transparent {
  background-color: transparent;
  border: 1px solid transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.sk-input-transparent:hover {
  background-color: $bgGray200;
  border: 1px solid $borderGray600;
}
.sk-input-transparent:focus {
  background-color: #fff;
  border: 1px solid $borderBlue500;
  box-shadow: inset 0 0 0 1px $borderBlue500;
}
.sk-input-flush {
  padding: 0 6px 0 6px !important;
  margin-left: -6px;
}
.sk-height-reset {
  /* Input inherit heading height */
  height: inherit;
}

/* Content Editable */
.sk-contenteditable {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0 6px 0 6px !important;
  margin-left: -6px;
}
.sk-contenteditable:hover {
  background-color: $bgGray300;
}
.sk-contenteditable:focus {
  background-color: #fff;
  border: 1px solid $borderBlue500;
  box-shadow: inset 0 0 0 1px $borderBlue500;
}
.sk-contenteditable.height-34 {
  line-height: 34px;
}
.sk-contenteditable.height-38 {
  line-height: 38px;
}

/*---------------------
SWITCH
----------------------*/
/* proto.io/freebies/onoff/ */
.sk-onoffswitch {
  position: relative;
  text-align: left;
  width: 60px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.sk-onoffswitch-checkbox {
  display: none;
}
label.sk-onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 0;
}
.sk-onoffswitch-switch {
  display: block;
  width: 22px;
  height: 22px;
  margin: 3px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 32px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.sk-onoffswitch-checkbox:checked + .sk-onoffswitch-label .sk-onoffswitch-inner {
  margin-left: 0;
}
.sk-onoffswitch-checkbox:checked
  + .sk-onoffswitch-label
  .sk-onoffswitch-switch {
  right: 0px;
}

/*---------------------
SWITCH w/ ICONS
----------------------*/
.sk-onoffswitch-icon-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.sk-onoffswitch-icon-inner:before,
.sk-onoffswitch-icon-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 28px;
  font-size: 13px;
  color: white;
  font-weight: 500;
  box-sizing: border-box;
}
.sk-onoffswitch-icon-inner:before {
  font-family: 'Material Icons';
  font-size: 20px;
  font-weight: 600;
  content: '\E5CA';
  /* ON */
  padding-left: 10px;
  background-color: $bgGreen500;
  color: #ffffff;
}
.sk-onoffswitch-icon-inner:after {
  font-family: 'Material Icons';
  font-size: 20px;
  font-weight: 600;
  content: '\E5CD';
  /* OFF */
  padding-right: 10px;
  background-color: #c9d2d9;
  color: #777;
  text-align: right;
}
.sk-onoffswitch-checkbox:checked
  + .sk-onoffswitch-label
  .sk-onoffswitch-icon-inner {
  margin-left: 0;
}

/* Disabled Switches */
.sk-onoffswitch.disabled .sk-onoffswitch-switch {
  cursor: not-allowed;
}
.sk-onoffswitch.disabled .sk-onoffswitch-icon-inner:before,
.sk-onoffswitch.disabled .sk-onoffswitch-icon-inner:after {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Small Switches */
.sk-onoffswitch.sk-switch-sm {
  width: 40px;
}
.sk-onoffswitch.sk-switch-sm .sk-onoffswitch-icon-inner:before,
.sk-onoffswitch.sk-switch-sm .sk-onoffswitch-icon-inner:after {
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  color: white;
  font-weight: 500;
  box-sizing: border-box;
}
.sk-onoffswitch.sk-switch-sm .sk-onoffswitch-icon-inner:before {
  content: ' ';
}
.sk-onoffswitch.sk-switch-sm .sk-onoffswitch-icon-inner:after {
  content: ' ';
  background-color: #c0cad3;
}
.sk-onoffswitch.sk-switch-sm .sk-onoffswitch-switch {
  width: 14px;
  height: 14px;
  right: 20px;
}

/*---------------------
RANGE SLIDER
----------------------*/
.sk-range-key {
  display: flex;
  padding: 2px 6px 0;
  justify-content: space-between;
}
.sk-tick-mark {
  font-size: 12px;
  color: $textMuted;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 1px;
  white-space: nowrap;
}
.sk-tick-mark .tick-label {
  display: flex;
  justify-content: center;
}
.sk-tick-mark:before {
  background-color: $bgGray400;
  content: ' ';
  display: flex;
  height: 6px;
  justify-content: center;
  padding-bottom: 3px;
}
.sk-range-value {
  width: 80px;
  font-weight: 500;
  padding-left: 1rem;
  white-space: nowrap;
}

/*---------------------
MATERIAL DATEPICKER OVERRIDE
material.angularjs.org/1.1.1/demo/datepicker
----------------------*/

/* GENERAL */
.sk-md-datepicker md-datepicker {
  position: relative;
  padding-right: 40px;
  margin-right: 0;
  line-height: 34px;
}
.sk-stacked-datepicker select {
  width: 254px;
}

/* Bootstrap Modal z-index:1050; */
.md-scroll-maks {
  z-index: 1060;
}
.md-datepicker-calendar-pane {
  z-index: 1070;
}

/* Input */
.md-datepicker-input-container {
  padding-bottom: 0;
  border: none;
}
.md-icon-button + .md-datepicker-input-container {
  margin-left: 0;
  height: 34px;
}
.md-datepicker-input {
  background-color: #fff;
  border: 1px solid $borderGray700;
  border-radius: 0.25rem 0 0 0.25rem;
  color: $textFormInput;
  height: 34px;
  padding: 0 1rem;
  min-width: 143px;
  max-width: 143px;
}
.md-datepicker-input:hover {
  border-color: #4fb4f8;
  background-color: #fff;
}
.sk-input-md .md-datepicker-input {
  min-width: 215px;
}
.sk-input-lg .md-datepicker-input {
  min-width: 280px;
}

/* Datepicker Button */
.md-datepicker-button.md-icon-button {
  background-color: #fff;
  border: 1px solid #becbdc;
  border-radius: 0 0.25rem 0.25rem 0;
  height: 34px;
  left: 142px;
  min-height: 34px;
  padding: 0;
  position: absolute;
  margin: 0;
}
.md-datepicker-button.md-icon-button:not([disabled]):hover md-icon {
  color: rgba(74, 82, 93, 0.9);
}
.sk-input-md .md-datepicker-button.md-icon-button {
  left: 214px;
}
.sk-input-lg .md-datepicker-button.md-icon-button {
  left: 279px;
}
.md-datepicker-button .md-icon.md-datepicker-calendar-icon {
  color: rgba(74, 82, 93, 0.8);
}
.md-button.md-icon-button .md-ripple-container {
  display: none;
}

/* Triangle Button */
.md-datepicker-triangle-button.md-button.md-icon-button {
  display: none;
}

/* Focused */
.md-datepicker-focused input {
  border-color: #5caff9;
  box-shadow:
    0 0 0 2px #95ceff,
    inset 0px 1px 2px 0px #e5e8ea;
}

/* Opened */
.md-datepicker-open .md-datepicker-input-container input.md-datepicker-input {
  height: 34px;
  border-color: transparent;
}
.md-datepicker-open .md-datepicker-button.md-icon-button {
  border-color: transparent;
}

/* Invalid */
.md-datepicker-input-container.md-datepicker-invalid input,
.sk-md-datepicker.has-danger input {
  border-color: $inputDanger;
  box-shadow: 0 0 0 1px $inputDanger;
}
.md-datepicker-input-container.md-datepicker-invalid.md-datepicker-focused
  input,
.sk-md-datepicker.has-danger input:focus {
  box-shadow: 0 0 0 2px rgba(250, 97, 115, 0.5);
}

/* Colors */
.md-default-theme
  .md-calendar-date.md-calendar-selected-date
  .md-calendar-date-selection-indicator,
.md-calendar-date.md-calendar-selected-date
  .md-calendar-date-selection-indicator,
.md-default-theme
  .md-calendar-date.md-focus.md-calendar-selected-date
  .md-calendar-date-selection-indicator,
.md-calendar-date.md-focus.md-calendar-selected-date
  .md-calendar-date-selection-indicator {
  background: #0082e6;
}
.md-default-theme
  .md-calendar-date.md-calendar-date-today
  .md-calendar-date-selection-indicator,
.md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
  border: 1px solid rgb(0, 129, 230);
}
.md-default-theme
  .md-calendar-date.md-calendar-date-today.md-calendar-date-disabled,
.md-calendar-date.md-calendar-date-today.md-calendar-date-disabled {
  color: rgb(0, 143, 255);
}
