@import './variables';

/*---------------------
LABELS (BADGES)
----------------------*/

.label,
.badge {
  // DEPRECATE .label
  font-size: 13px;
  font-weight: 400;
}
.label,
.badge {
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
}
.label-sm,
.badge-sm {
  font-size: 12px;
  line-height: 18px;
  height: 18px;
  padding: 0 6px;
}
.label-primary,
.bg-primary {
  color: #ffffff;
  background-color: $bgBlue600;
}
.label-basic,
.badge-basic {
  color: $textBody;
  background-color: transparent;
}
.label-default,
.badge-default {
  color: $textBody;
  background-color: $bgGray300;
}
.label-default-bold,
.badge-default-bold {
  color: #ffffff;
  background-color: $bgGray600;
}
.label-info,
.badge-info {
  color: $textBody;
  background-color: $bgTeal200;
}
.label-success,
.badge-success {
  color: $textBody;
  background-color: $bgGreen200;
}
.label-warning,
.badge-warning {
  color: $textBody;
  background-color: $bgYellow300;
}
.label-danger,
.badge-danger {
  color: $textBody;
  background-color: $bgRed200;
}
.label-danger-bold,
.badge-danger-bold {
  color: #ffffff;
  background-color: $bgRed500;
  font-weight: 500;
}

/* Hover */
a.badge-info,
a.badge-danger {
  color: $textBody !important;
}
a.badge-info:focus,
a.badge-info:hover {
  color: $textBody !important;
  background-color: $bgTeal250;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: $textBody !important;
  background-color: $bgRed250;
}
a.badge-info.focus,
a.badge-info:focus,
a.badge-danger.focus,
a.badge-danger:focus {
  box-shadow: none;
}
/* Bootstrap override */
.btn .badge {
  top: 0;
}

/*---------------------
TAGS
----------------------*/

.sk-tag-basic {
  background-color: $bgGray300;
  border: 1px solid $bgGray300;
}
.sk-tag .material-icons {
  font-size: 14px;
  vertical-align: middle;
  margin-left: 5px;
  line-height: 19px;
}
.sk-tag {
  color: $textDark;
  font-weight: 400;
  padding: 0 0.5rem;
  height: 20px;
  line-height: 18px !important;
  margin-right: 5px;
}
.sk-tag-badge {
  color: $textMuted;
}
.btn.active .sk-tag-badge {
  color: #c2d5e7;
}
a .sk-tag:hover {
  background-color: $bgGray300;
  border: 1px solid $bgGray300;
  color: $textDark;
  text-decoration: none;
}
a.sk-tag:hover {
  background-color: $bgGray300;
  border: 1px solid $bgGray300;
  color: $textDark;
  text-decoration: none;
}
.sk-tag-group .sk-tag {
  margin-bottom: 5px;
}
.sk-tag-group .sk-clear-btn {
  line-height: 24px;
  margin-left: 6px;
}

/* TAGS INPUT */

.sk-tags-input {
  display: block;
}
.sk-tags-input .sk-tag-container {
  position: relative;
  height: 100%;
}
.sk-tags-input .sk-tags {
  background-color: #fff;
  border: 1px solid $borderGray700;
  border-radius: 0.25rem;
  cursor: text;
  height: 100%;
  padding: 2px;
  overflow: hidden;
  word-wrap: break-word;
}
.sk-tags-input .sk-tags .sk-tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sk-tags-input .sk-tags .sk-tag-item {
  float: left;
  margin: 2px;
}
.sk-tags-input .sk-tags .sk-tag-item .sk-tag {
  margin: 0;
}
.sk-tags-input .sk-tags .sk-input {
  background: #fff;
  border: 0;
  outline: 0;
  margin: 2px;
  padding: 0 0 0 5px;
  float: left;
  height: 20px;
}
.sk-tags-input .sk-tags .sk-input::-webkit-input-placeholder {
  color: #949ca2;
}
.sk-tags-input .sk-tags .sk-input:-moz-placeholder {
  color: #949ca2;
}
.sk-tags-input .sk-tags .sk-input::-moz-placeholder {
  color: #949ca2;
}
.sk-tags-input .sk-tags .sk-input:-ms-input-placeholder {
  color: #949ca2;
}
.sk-tags-input .sk-tags:hover {
  border-color: $borderBlue500;
  background-color: #fff;
}
.sk-tags-input .sk-tags:focus,
.sk-tags-input .sk-tags.focus {
  border-color: $borderBlue500;
  background-color: #fff;
  box-shadow: 0 0 0 1px $borderBlue500;
}

/* Legacy Tags Input - NOT sk-tags-input */
/* Legacy Tags Input - NOT sk-tags-input */
/* Legacy Tags Input - NOT sk-tags-input */

tags-input {
  display: block;
}
tags-input *,
tags-input *:before,
tags-input *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
tags-input .host {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 100%;
}
tags-input .host:active {
  outline: none;
}
tags-input .sk-tags.focused {
  outline: none;
  -webkit-box-shadow: 0 0 0px 1px rgba(5, 139, 242, 0.6);
  -moz-box-shadow: 0 0 0px 1px rgba(5, 139, 242, 0.6);
  box-shadow: 0 0 0px 1px rgba(5, 139, 242, 0.6);
}
tags-input .sk-tags .sk-tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
tags-input .sk-tags .sk-tag-item .remove-button {
  margin: 0 0 0 5px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  vertical-align: middle;
  font:
    bold 16px Arial,
    sans-serif;
  color: #585858;
}
tags-input .sk-tags .sk-tag-item .remove-button:active {
  color: #fa6173;
}
tags-input .sk-tags .input {
  border: 0;
  outline: none;
  margin: 2px;
  padding: 0;
  padding-left: 5px;
  float: left;
  height: 26px;
  font:
    14px 'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif;
}
tags-input .sk-tags .input.invalid-tag {
  color: #fa6173;
}
tags-input .sk-tags .input::-ms-clear {
  display: none;
}
tags-input.ng-invalid .sk-tags {
  -webkit-box-shadow: 0 0 0px 1px #fa6173;
  -moz-box-shadow: 0 0 0px 1px #fa6173;
  box-shadow: 0 0 0px 1px #fa6173;
}
tags-input[disabled] .host:focus {
  outline: none;
}
tags-input[disabled] .sk-tags {
  cursor: default;
}
tags-input[disabled] .sk-tags .sk-tag-item {
  opacity: 0.65;
}
tags-input[disabled] .sk-tags .sk-tag-item .remove-button {
  cursor: default;
}
tags-input[disabled] .sk-tags .sk-tag-item .remove-button:active {
  color: #585858;
}
tags-input[disabled] .sk-tags .input {
  cursor: default;
}
tags-input[disabled] .sk-tags:hover {
  cursor: not-allowed;
}
tags-input[disabled] .sk-tags .sk-tag-item:hover {
  cursor: not-allowed;
  opacity: 0.65;
}
tags-input[disabled] .sk-tags .sk-tag-item .remove-button:hover {
  cursor: not-allowed;
}
tags-input[disabled] .sk-tags .sk-tag-item .remove-button:active:hover {
  cursor: not-allowed;
  color: #585858;
}
tags-input[disabled] .sk-tags .input:hover {
  cursor: not-allowed;
}
tags-input .autocomplete {
  margin-top: 5px;
  position: absolute;
  padding: 5px 0;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
tags-input .autocomplete .suggestion-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 280px;
  overflow-y: auto;
  position: relative;
}
tags-input .autocomplete .suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font:
    16px 'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif;
  color: #000;
  background-color: #fff;
}
tags-input .autocomplete .suggestion-item.selected {
  color: #fff;
  background-color: #0097cf;
}
tags-input .autocomplete .suggestion-item.selected em {
  color: #fff;
  background-color: #0097cf;
}
tags-input .autocomplete .suggestion-item em {
  font:
    normal bold 16px 'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif;
  color: #000;
  background-color: #fff;
}

/*# sourceMappingURL=ng-tags-input.css.map */
tags-input {
  box-shadow: none;
  border: none;
  padding: 0;
  min-height: 34px;
}
tags-input .host {
  margin: 0;
}
tags-input .sk-tags {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -moz-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}
tags-input .sk-tags .sk-tag-item.selected {
  color: #fff;
  -webkit-box-shadow: 0px 0px 0px 1px #24b6ff;
  box-shadow: 0px 0px 0px 1px #24b6ff;
  border-radius: 0.25em;
}
tags-input .sk-tags .sk-tag-item .remove-button:hover {
  text-decoration: none;
}
tags-input .sk-tags.focused {
  border: 1px solid #66afe9;
  -webkit-box-shadow: 0 0 0px 1px rgba(5, 139, 242, 0.6);
  -moz-box-shadow: 0 0 0px 1px rgba(5, 139, 242, 0.6);
  box-shadow: 0 0 0px 1px rgba(5, 139, 242, 0.6);
}
tags-input .autocomplete {
  border-radius: 4px;
}
tags-input .autocomplete .suggestion-item.selected {
  color: #262626;
  background-color: #f5f5f5;
}
tags-input .autocomplete .suggestion-item.selected em {
  color: #262626;
  background-color: #f5f5f5;
}
tags-input .autocomplete .suggestion-item em {
  color: #000;
  background-color: #fff;
}
tags-input.ng-invalid .sk-tags {
  border-color: #fa6173;
}
tags-input[disabled] .sk-tags {
  background-color: #eee;
}
tags-input[disabled] .sk-tags:hover {
  background-color: #eee;
}
tags-input[disabled] .sk-tags .sk-tag-item {
  opacity: 0.65;
}
tags-input[disabled] .sk-tags .sk-tag-item:hover {
  opacity: 0.65;
}
.input-group tags-input {
  padding: 0;
  display: table-cell;
}
.input-group tags-input:not(:first-child) .sk-tags {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group tags-input:not(:last-child) .sk-tags {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-lg tags-input:first-child .sk-tags {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.input-group-lg tags-input:last-child .sk-tags {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.input-group-sm tags-input:first-child .sk-tags {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.input-group-sm tags-input:last-child .sk-tags {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
tags-input.ti-input-lg,
.input-group-lg tags-input {
  min-height: 46px;
}
tags-input.ti-input-lg .sk-tags,
.input-group-lg tags-input .sk-tags {
  border-radius: 6px;
}
tags-input.ti-input-lg .sk-tags .sk-tag-item,
.input-group-lg tags-input .sk-tags .sk-tag-item {
  height: 38px;
  line-height: 37px;
  font-size: 18px;
  border-radius: 6px;
}
tags-input.ti-input-lg .sk-tags .sk-tag-item .remove-button,
.input-group-lg tags-input .sk-tags .sk-tag-item .remove-button {
  font-size: 20px;
}
tags-input.ti-input-lg .sk-tags .input,
.input-group-lg tags-input .sk-tags .input {
  height: 38px;
  font-size: 18px;
}
tags-input.ti-input-sm,
.input-group-sm tags-input {
  min-height: 30px;
}
tags-input.ti-input-sm .sk-tags,
.input-group-sm tags-input .sk-tags {
  border-radius: 3px;
}
tags-input.ti-input-sm .sk-tags .sk-tag-item,
.input-group-sm tags-input .sk-tags .sk-tag-item {
  height: 22px;
  line-height: 21px;
  font-size: 12px;
  border-radius: 3px;
}
tags-input.ti-input-sm .sk-tags .sk-tag-item .remove-button,
.input-group-sm tags-input .sk-tags .sk-tag-item .remove-button {
  font-size: 16px;
}
tags-input.ti-input-sm .sk-tags .input,
.input-group-sm tags-input .sk-tags .input {
  height: 22px;
  font-size: 12px;
}
.has-feedback tags-input .sk-tags {
  padding-right: 30px;
}
.has-success tags-input .sk-tags {
  border-color: #3c763d;
}
.has-success tags-input .sk-tags.focused {
  border-color: #2b542c;
  -webkit-box-shadow:
    inset 0 0px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #67b168;
  -moz-box-shadow:
    inset 0 0px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #67b168;
  box-shadow:
    inset 0 0px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #67b168;
}
.has-error tags-input .sk-tags {
  border-color: #fa6173;
}
.has-error tags-input .sk-tags.focused {
  border-color: #fa6173;
}
.has-warning tags-input .sk-tags {
  border-color: #8a6d3b;
}
.has-warning tags-input .sk-tags.focused {
  border-color: #f0a800;
  -webkit-box-shadow:
    inset 0 0px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #f0a800;
  -moz-box-shadow:
    inset 0 0px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #f0a800;
  box-shadow:
    inset 0 0px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #f0a800;
}
.sk-tag-badge {
  display: inline-block;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  margin: 0;
}
.sk-tag-remove-button {
  color: #a0abb6;
  text-decoration: none;
  font-size: 16px !important;
}
.sk-tag-remove-button:hover {
  text-decoration: none;
}
sk-tag-remove-button .material-icons {
  margin-top: 0;
  margin-left: 0;
}
.sk-tags-input-control {
  height: 21px !important;
}
