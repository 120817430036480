@import './variables';

/*---------------------
PANELS
----------------------*/

/*---------------------
PANEL LEFT
----------------------*/

.sk-panel-left {
  border-right: 1px solid $borderGray500;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  overflow: auto;
  width: 16rem;
}
.sk-panel-body {
  overflow: auto; // fix vertical border on resize
}
.sk-panel-card {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $borderGray500;
  padding: 1rem 2rem;
}
.sk-panel-card:hover {
  background-color: $bgBlue50;
}
.sk-panel-card.active {
  background-color: $bgBlue100;
}
.sk-panel-card .panel-card-header {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 34px;
}
.sk-panel-card.card-md {
  min-height: 8rem;
}

/*---------------------
  PANEL RIGHT
  ----------------------*/

.sk-panel-right {
  background: #fff;
  border-left: 1px solid $borderGray500;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  width: 31rem;
}
.sk-panel-right .sk-panel-header {
  padding: 1rem 1.5rem 0;
  border-bottom: 1px solid $borderGray500;
}
.sk-panel-right .sk-panel-header.heading-only {
  padding-bottom: 1rem;
}
.sk-panel-header .sk-header-row {
  display: flex;
  justify-content: space-between;
}
.sk-panel-header .sk-header-row h3 {
  font-weight: 400;
  line-height: 34px;
}
.sk-panel-right .sk-panel-content {
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.sk-panel-scroll {
  overflow: auto;
  padding: 1rem 0;
}
.sk-panel-right .sk-panel-footer {
  display: flex;
  border-top: 1px solid $borderGray500;
  padding: 1rem 1.5rem;
}
.sk-panel-body ul.list-group li {
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.sk-panel-body ul.list-group li:first-child {
  border-top: none;
}
.sk-panel-search {
  display: flex;
  padding: 1rem 1.5rem;
}
.sk-panel-search.with-scroll {
  border-bottom: 1px solid $borderGray500;
  padding: 1rem 1.5rem 1rem 1.5rem;
}

/* Typeahead in Panel */

.sk-panel-right.panel-lg .sk-typeahead-overflow .dropdown-menu {
  max-width: 337px;
}
.sk-input-group.sk-typeahead-overflow .dropdown-menu {
  left: 0;
  right: auto;
  transform: translate(0px, 34px) !important;
}
.sk-input-group.sk-typeahead-overflow .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}
.sk-input-group.sk-typeahead-overflow .dropdown-item.active {
  font-weight: normal;
}

/* Task Lists in Panel */

.sk-task-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $borderGray500;
}
.sk-task-item .sk-task-icon {
  display: flex;
  align-items: center;
  width: 2rem;
  margin-right: 5px;
  margin-left: 3px;
}
.sk-task-item .sk-task-icon i:before {
  color: $bgGray400;
  content: '\e86c'; //check_circle
}
.sk-task-item.completed .sk-task-icon i:before {
  color: $bgBlue600;
  content: '\e86c'; //check_circle
}
.sk-task-item.completed .sk-task-title {
  text-decoration: line-through;
  color: $textMuted;
}
.sk-task-item .sk-task-body {
  display: flex;
  flex-direction: column;
  height: 48px;
  flex-grow: 1;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sk-task-item .sk-task-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sk-task-item .sk-task-meta {
  color: $textMuted;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Notification in Panel */

.sk-panel-notifications .sk-notification-item:not(:first-child) {
  border-top: 1px solid $borderGray500;
  padding-top: 1rem;
}
.sk-notification-item .sk-description-max {
  max-height: 60px;
  overflow: hidden;
}
.sk-notification-item .sk-more-toggle {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Nav Item Max Widths for text-truncate */

.panel-xs-1 .nav-item {
  max-width: 181px;
}
.panel-xs-2 .nav-item {
  max-width: 194px;
}
.panel-xs-3 .nav-item {
  max-width: 207px;
}
.panel-xs-4 .nav-item {
  max-width: 220px;
}
.panel-xs-5 .nav-item {
  max-width: 233px;
}

/* Static Panel Sizing */

.panel-xs-1 {
  width: 14rem;
}
.panel-xs-2 {
  width: 15rem;
}
.panel-xs-3 {
  width: 16rem;
}
.panel-xs-4 {
  width: 17rem;
}
.panel-xs-5 {
  width: 18rem;
}
.panel-sm {
  width: 25rem;
}
.panel-md {
  width: 27rem;
}

/* Responsive Panel Sizing */

.panel-lg {
  width: 31rem;
  &.sk-responsive {
    @media (min-width: 1600px) {
      width: 34rem;
    }
    @media (min-width: 1900px) {
      width: 37rem;
    }
  }
}
.panel-xl {
  width: 36rem;
  &.sk-responsive {
    @media (min-width: 1600px) {
      width: 39rem;
    }
    @media (min-width: 1900px) {
      width: 42rem;
    }
  }
}
.panel-xxl {
  width: 41rem;
  &.sk-responsive {
    @media (min-width: 1600px) {
      width: 44rem;
    }
    @media (min-width: 1900px) {
      width: 47rem;
    }
  }
}

/* Visibility */

.panel-hidden {
  display: none;
}
